/*eslint-disable*/

import Logo from "../assets/image/LogoTJS.jpeg";
import {
  IoServerOutline,
  IoCashOutline,
  IoSpeedometerOutline,
  IoPersonCircleOutline,
  IoDocumentTextOutline,
} from "react-icons/io5";

import {
  // Buaso
  Buaso,
  Satuan,
  Kelompok,
  Jenis,
  Pabrikan,
  Gudang,

  // Registrasi BUASO
  RegistrasiItemBahan,
  RegistrasiAlatMesin,

  // Delivery Order
  DeliveryOrder,
  ListSo,
  TambahDeliveryOrder,
  EditDeliveryOrder,
  DetailDeliveryOrder,

  // Purchase Request
  PurchaseRequest,
  TambahPurchaseRequest,
  UbahPurchaseRequest,
  DetailPurchaseRequest,

  // Surat Jalan
  ListSuratJalan,
  ListDeliveryOrderSuratJalan,
  TambahSuratJalan,
  UbahSuratJalan,
  DetailSuratJalan,

  // Pengembalian Barang
  PengembalianBarang,
  TambahPengembalianBarang,
  EditPengembalianBarang,
  DetailPengembalianBarang,

  // Mapping Unit Produksi
  MappingUnitProduksi,

  // Penerimaan & Pembatalan Retur
  ListFakturDiBatalkan,
  PenerimaanRetur,
  ListReturPenjulan,
  TambahPenerimaanRetur,
  EditPenerimaanRetur,
  DetailPenerimaanRetur,

  // Penerimaan Barang
  PenerimaanBarang,
  ListPurchaseOrder,
  TambahPenerimaanBarang,
  UbahPenerimaanBarang,

  // Permintaan Bahan Produksi
  PermintaanBahanProduksi,
  DataPermintaanBahanProduksi,
  CetakPermintaanBahanProduksi,
  DetailCetakPermintaanProduksi,

  // Realisasi Sisa produksi
  RealisasiSisaProduksi,
  ListTransferSisaProduksi,

  // Penerimaan Surat Jalan
  PenerimaanSuratJalan,

  // Mutasi Antar Gudang
  MutasiAntarGudang,
  MainApproval,

  // Transfer Bahan Job Mix
  TransferBahanJobMix,
  ListPermintaanBahanJobMix,
  TambahTransferBahanJobMix,

  // Laporan
  KartuStockMaterial,
  LaporanPurchaseRequest,
  LaporanDeliveryOrder,
  LaporanStockUpdate,
  StokTerbaru,

  // Mapping Item
  MappingItem,

  // Setup Stok Awal
  SetupStokAwal,

  // Item Barang
  ListItemBarang,
  TambahItemBarang,
  UbahItemBarang,
  DetailItemBarang,
  RekapitulasiNilaiPersediaan,
  // Laporan Penerimaan Barang
  LaporanPenerimaanBarang,
  MonitoringOrder,
  ListStockFisik,
  TambahStockFisik,
  DetailStockFisik,
  UbahStockFisik,
  ListStockOpname,
  TambahStockOpname,
  ListStockFisikOpname,
  DetailStockOpname,
  ListConvertItem,
  TambahConvertItem,
  DetailConvertItem,
  LaporanSuratJalan,

  // Transfer Bahan Work Order
  ListTransferBahanWorkOrder,
  ListPermintaanBahanWorkOrder,
  TambahTransferBahanWorkOrder,
  LaporanAktivitasHarian,
  TransferBahanProduksiAsetList,
} from "../pages/Inventory";

export default {
  LOGO: Logo,
  MODUL: "Inventori",

  /* 
    MENU REQUIREMENTS
      > TEXT  = REQUIRED,
      > LINK  = REQUIRED,
      > EXACT = OPTIONAL (TRUE/FALSE),
      > TYPE  = REQUIRED (MENU/DROPDOWN)
      > HAK   = REQUIRED (HARUS BERBENTUK ARRAY)
      > LOGO  = OPTIONAL 
  */

  MENU: [
    {
      text: "Dashboard",
      link: "/",
      type: "menu",
      exact: true,
      icon: <IoSpeedometerOutline />,
      hak: ["SUPA", "INV"],
    },
    {
      text: "Master Data",
      type: "dropdown",
      icon: <IoServerOutline />,
      hak: ["SUPA", "INV"],
      menu: [
        {
          text: "BUASO",
          link: "/inventory/master/buaso",
          hak: ["SUPA", "INV_MAS_BUA"],
        },
        {
          text: "Satuan",
          link: "/inventory/master/satuan",
          hak: ["SUPA", "INV_MAS_SAT"],
        },
        {
          text: "Kelompok Barang",
          link: "/inventory/master/kelompok",
          hak: ["SUPA", "INV_MAS_KEL"],
        },
        {
          text: "Jenis Barang",
          link: "/inventory/master/jenis-bahan",
          hak: ["SUPA", "INV_MAS_JEN"],
        },
        {
          text: "Pabrikan",
          link: "/inventory/master/pabrikan",
          hak: ["SUPA", "INV_MAS_PAB"],
        },
        {
          text: "Gudang",
          link: "/inventory/master/gudang",
          hak: ["SUPA", "INV_MAS_GUD"],
        },
        {
          text: "Item Barang",
          link: "/inventory/transaksi/register-item-bahan",
          hak: ["SUPA", "INV_REG_BHN"],
        },
        // {
        //   text: "Item Alat dan Mesin",
        //   link: "/inventory/transaksi/register-alat-dan-mesin",
        //   hak: ["SUPA", "INV_REG_ALM"],
        // },
      ],
    },
    {
      text: "Transaksi",
      type: "dropdown",
      icon: <IoCashOutline />,
      hak: [
        "SUPA",
        "INV",
        "INV_TRN_PR",
        "INV_TRN_DO",
        "INV_TRN_SJ",
        "INV_TRN_PBJ",
        "INV_TRN_PSJ",
        "INV_TRN_MAG",
        "INV_TRN_MIG",
        "INV_TRN_TJB",
      ],
      menu: [
        // {
        //   text: "Mapping Unit Produksi",
        //   link: "/inventory/transaksi/mapping-unit-produksi",
        //   hak: ["SUPA", "INV_REG_BHN"],
        // },
        {
          text: "Purchase Request",
          link: "/inventory/transaksi/purchase-request",
          hak: ["SUPA", "INV_TRN_PR"],
        },

        {
          text: "Delivery Order",
          link: "/inventory/transaksi/delivery-order",
          hak: ["SUPA", "INV_TRN_DO"],
        },

        {
          text: "Surat Jalan",
          link: "/inventory/transaksi/surat-jalan",
          hak: ["SUPA", "INV_TRN_SJ"],
        },
        // {
        //   text: "Pengembalian Barang",
        //   link: "/inventory/transaksi/pengembalian-barang",
        //   hak: ["SUPA", "INV_REG_OVH"],
        // },
        // {
        //   text: "Penerimaan Retur",
        //   link: "/inventory/transaksi/penerimaan-retur",
        //   hak: ["SUPA", "INV_REG_OVH"],
        // },
        {
          text: "Penerimaan Barang",
          link: "/inventory/transaksi/penerimaan-barang",
          hak: ["SUPA", "INV_TRN_PBJ"],
        },
        // {
        //   text: "Transfer Bahan Produksi",
        //   link: "/inventory/transaksi/transfer-produksi-bahan",
        //   hak: ["SUPA", "INV_TRN_TPB"],
        // },
        {
          text: "Transfer Bahan Produksi",
          link: "/inventory/transaksi/transfer-bahan-job-mix",
          hak: ["SUPA", "INV_TRN_TJB"],
        },
        // {
        //   text: "Realisasi Sisa Produksi",
        //   link: "/inventory/transaksi/realisasi-sisa-produksi",
        //   hak: ["SUPA", "INV_TRN_RSP"],
        // },
        {
          text: "Penerimaan Surat Jalan",
          link: "/inventory/transaksi/penerimaan-surat-jalan",
          hak: ["SUPA", "INV_TRN_PSJ"],
        },
        {
          text: "Mutasi Antar Gudang",
          link: "/inventory/transaksi/mutasi-antar-gudang",
          hak: ["SUPA", "INV_TRN_MAG"],
        },
        {
          text: "Mapping Item Terhadap Gudang",
          link: "/inventory/transaksi/mapping-item",
          hak: ["SUPA", "INV_TRN_MIG"],
        },
        {
          text: "Setup Stok Awal",
          link: "/inventory/transaksi/setup-stok-awal",
          hak: ["SUPA", "INV_TRN_SSA"],
        },
        {
          text: "Monitoring Order",
          link: "/inventory/transaksi/monitoring-order",
          hak: ["SUPA", "INV_TRN_MTO"],
        },
        {
          text: "Stock Fisik",
          link: "/inventory/transaksi/stock-fisik",
          hak: ["SUPA", "INV_TRN_STF"],
        },
        {
          text: "Stock Opname",
          link: "/inventory/transaksi/stock-opname",
          hak: ["SUPA", "INV_TRN_STO"],
        },
        {
          text: "Convert Item",
          link: "/inventory/transaksi/convert-item",
          hak: ["SUPA", "INV_TRN_CVI"],
        },
        {
          text: "Transfer Bahan Work Order",
          link: "/inventory/transaksi/transfer-bahan-work-order",
          hak: ["SUPA", "INV_TRN_TWB"],
        },
        {
          text: "Transfer Bahan Produksi Aset",
          link: "/inventory/transaksi/transfer-bahan-produksi-aset",
          hak: ["SUPA", "INV_TRN_TBPA"],
        },
      ],
    },
    {
      text: "Laporan",
      type: "dropdown",
      icon: <IoDocumentTextOutline />,
      hak: ["SUPA", "INV"],
      menu: [
        {
          text: "Kartu Stok Barang",
          link: "/inventory/laporan/kartu-stock-material",
          hak: ["SUPA", "INV_LAP_KST"],
        },
        {
          text: "Stok Terbaru",
          link: "/inventory/laporan/stock-update",
          hak: ["SUPA", "INV_RPT_SU"],
        },
        {
          text: "Purchase Request",
          link: "/inventory/laporan/purchase-request",
          hak: ["SUPA", "INV_RPT_LPR"],
        },
        {
          text: "Delivery Order",
          link: "/inventory/laporan/delivery-order",
          hak: ["SUPA", "INV_RPT_DO"],
        },
        {
          text: "Rekapitulasi Nilai Persediaan",
          link: "/inventory/laporan/rekapitulasi-nilai-persediaan",
          hak: ["SUPA", "INV_RPT_RNP"],
        },
        {
          text: "Penerimaan Barang",
          link: "/inventory/laporan/penerimaan-barang",
          hak: ["SUPA", "INV_RPT_PBJ"],
        },
        {
          text: "Surat Jalan",
          link: "/inventory/laporan/surat-jalan",
          hak: ["SUPA", "INV_RPT_SJN"],
        },
        {
          text: "Harian Aktivitas Inventori",
          link: "/inventory/laporan/harian-aktivitas-inventori",
          hak: ["SUPA", "INV_RPT_ATH"],
        },
      ],
    },
  ],

  ROUTES: [
    // MASTER
    {
      exact: true,
      path: "/inventory/master/buaso",
      page: Buaso,
      hak: ["SUPA", "INV_MAS_BUA"],
    },
    {
      exact: true,
      path: "/inventory/master/satuan",
      page: Satuan,
      hak: ["SUPA", "INV_MAS_SAT"],
    },
    {
      exact: true,
      path: "/inventory/master/kelompok",
      page: Kelompok,
      hak: ["SUPA", "INV_MAS_KEL"],
    },
    {
      exact: true,
      path: "/inventory/master/jenis-bahan",
      page: Jenis,
      hak: ["SUPA", "INV_MAS_JEN"],
    },
    {
      exact: true,
      path: "/inventory/master/pabrikan",
      page: Pabrikan,
      hak: ["SUPA", "INV_MAS_PAB"],
    },
    {
      exact: true,
      path: "/inventory/master/gudang",
      page: Gudang,
      hak: ["SUPA", "INV_MAS_GUD"],
    },

    // Item Barang
    {
      exact: true,
      path: "/inventory/transaksi/register-item-bahan",
      page: ListItemBarang,
      hak: ["SUPA", "INV_REG_BHN"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/register-item-bahan/tambah",
      page: TambahItemBarang,
      hak: ["SUPA", "INV_REG_BHN"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/register-item-bahan/ubah/:id",
      page: UbahItemBarang,
      hak: ["SUPA", "INV_REG_BHN"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/register-item-bahan/detail/:id",
      page: DetailItemBarang,
      hak: ["SUPA", "INV_REG_BHN"],
    },

    // TRANSAKSI
    {
      exact: true,
      path: "/inventory/transaksi/mapping-unit-produksi",
      page: MappingUnitProduksi,
      hak: ["SUPA", "INV_REG_BHN"],
    },
    // {
    //   exact: true,
    //   path: "/inventory/transaksi/register-alat-dan-mesin",
    //   page: RegistrasiAlatMesin,
    //   hak: ["SUPA", "INV_REG_ALM"],
    // },

    // Delivery Order
    {
      exact: true,
      path: "/inventory/transaksi/delivery-order",
      page: DeliveryOrder,
      hak: ["SUPA", "INV_TRN_DO"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/delivery-order/list-so",
      page: ListSo,
      hak: ["SUPA", "INV_TRN_DO"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/delivery-order/tambah-delivery-order/:id_sales_order",
      page: TambahDeliveryOrder,
      hak: ["SUPA", "INV_TRN_DO"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/delivery-order/edit-delivery-order/:id",
      page: EditDeliveryOrder,
      hak: ["SUPA", "INV_TRN_DO"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/delivery-order/detail-delivery-order/:id",
      page: DetailDeliveryOrder,
      hak: ["SUPA", "INV_TRN_DO"],
    },

    // Surat Jalan
    {
      exact: true,
      path: "/inventory/transaksi/surat-jalan",
      page: ListSuratJalan,
      hak: ["SUPA", "INV_TRN_SJ"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/surat-jalan/delivery-order",
      page: ListDeliveryOrderSuratJalan,
      hak: ["SUPA", "INV_TRN_SJ"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/surat-jalan/tambah/:id",
      page: TambahSuratJalan,
      hak: ["SUPA", "INV_TRN_SJ"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/surat-jalan/ubah/:id",
      page: UbahSuratJalan,
      hak: ["SUPA", "INV_TRN_SJ"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/surat-jalan/detail/:id",
      page: DetailSuratJalan,
      hak: ["SUPA", "INV_TRN_SJ"],
    },

    // Purchase Request
    {
      exact: true,
      path: "/inventory/transaksi/purchase-request",
      page: PurchaseRequest,
      hak: ["SUPA", "INV_TRN_PR"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/purchase-request/tambah",
      page: TambahPurchaseRequest,
      hak: ["SUPA", "INV_TRN_PR"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/purchase-request/:id",
      page: UbahPurchaseRequest,
      hak: ["SUPA", "INV_TRN_PR"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/purchase-request/detail/:id",
      page: DetailPurchaseRequest,
      hak: ["SUPA", "INV_TRN_PR"],
    },

    // Pengembalian Barang
    {
      exact: true,
      path: "/inventory/transaksi/pengembalian-barang",
      page: PengembalianBarang,
      hak: ["SUPA", "INV_REG_OVH"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/pengembalian-barang/faktur-dibatalkan/tambah-pengembalian-barang/:id",
      page: TambahPengembalianBarang,
      hak: ["SUPA", "INV_REG_OVH"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/pengembalian-barang/edit-pengembalian-barang/:id",
      page: EditPengembalianBarang,
      hak: ["SUPA", "INV_REG_OVH"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/pengembalian-barang/detail-pengembalian-barang/:id",
      page: DetailPengembalianBarang,
      hak: ["SUPA", "INV_REG_OVH"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/pengembalian-barang/faktur-dibatalkan",
      page: ListFakturDiBatalkan,
      hak: ["SUPA", "INV_REG_OVH"],
    },

    // Penerimaan Retur
    {
      exact: true,
      path: "/inventory/transaksi/penerimaan-retur",
      page: PenerimaanRetur,
      hak: ["SUPA", "INV_REG_OVH"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/penerimaan-retur/list-faktur-penjualan/tambah-penerimaan-retur/:id",
      page: TambahPenerimaanRetur,
      hak: ["SUPA", "INV_REG_OVH"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/penerimaan-retur/edit-penerimaan-retur/:id",
      page: EditPenerimaanRetur,
      hak: ["SUPA", "INV_REG_OVH"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/penerimaan-retur/detail-penerimaan-retur/:id",
      page: DetailPenerimaanRetur,
      hak: ["SUPA", "INV_REG_OVH"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/penerimaan-retur/list-faktur-penjualan",
      page: ListReturPenjulan,
      hak: ["SUPA", "INV_REG_OVH"],
    },

    // Penerimaan Barang
    {
      exact: true,
      path: "/inventory/transaksi/penerimaan-barang",
      page: PenerimaanBarang,
      hak: ["SUPA", "INV_TRN_PBJ"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/penerimaan-barang/list",
      page: ListPurchaseOrder,
      hak: ["SUPA", "INV_TRN_PBJ"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/penerimaan-barang/tambah/:id",
      page: TambahPenerimaanBarang,
      hak: ["SUPA", "INV_TRN_PBJ"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/penerimaan-barang/ubah/:id",
      page: UbahPenerimaanBarang,
      hak: ["SUPA", "INV_TRN_PBJ"],
    },

    // Transfer Produksi Bahan
    // {
    //   exact: true,
    //   path: "/inventory/transaksi/transfer-produksi-bahan",
    //   page: PermintaanBahanProduksi,
    //   hak: ["SUPA", "INV_TRN_PBP"],
    // },
    // {
    //   exact: true,
    //   path: "/inventory/transaksi/transfer-produksi-bahan/data",
    //   page: DataPermintaanBahanProduksi,
    //   hak: ["SUPA", "INV_TRN_PBP"],
    // },
    // {
    //   exact: true,
    //   path: "/inventory/transaksi/transfer-produksi-bahan/cetak",
    //   page: CetakPermintaanBahanProduksi,
    //   hak: ["SUPA", "INV_TRN_PBP"],
    // },
    // {
    //   exact: true,
    //   path: "/inventory/transaksi/transfer-produksi-bahan/cetak/detail/:id",
    //   page: DetailCetakPermintaanProduksi,
    //   hak: ["SUPA", "INV_TRN_PBP"],
    // },

    // Realisasi Sisa Produksi
    // {
    //   exact: true,
    //   path: "/inventory/transaksi/realisasi-sisa-produksi",
    //   page: RealisasiSisaProduksi,
    //   hak: ["SUPA", "INV_TRN_RSP"],
    // },
    // {
    //   exact: true,
    //   path: "/inventory/transaksi/realisasi-sisa-produksi/list-transfer-sisa-produksi",
    //   page: ListTransferSisaProduksi,
    //   hak: ["SUPA", "INV_TRN_RSP"],
    // },

    // Penerimaan Surat Jalan
    {
      exact: true,
      path: "/inventory/transaksi/penerimaan-surat-jalan",
      page: PenerimaanSuratJalan,
      hak: ["SUPA", "INV_TRN_PSJ"],
    },

    // Mutasi Antar Gudang
    {
      exact: true,
      path: "/inventory/transaksi/mutasi-antar-gudang",
      page: MutasiAntarGudang,
      hak: ["SUPA", "INV_TRN_MAG"],
    },

    {
      exact: true,
      path: "/inventory/transaksi/mutasi-antar-gudang/approval",
      page: MainApproval,
      hak: ["SUPA", "INV_TRN_MAG"],
    },

    // Transfer Bahan Job Mix
    {
      exact: true,
      path: "/inventory/transaksi/transfer-bahan-job-mix",
      page: TransferBahanJobMix,
      hak: ["SUPA", "INV_TRN_TJB"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/transfer-bahan-job-mix/list-permintaan-bahan",
      page: ListPermintaanBahanJobMix,
      hak: ["SUPA", "INV_TRN_TJB"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/transfer-bahan-job-mix/tambah/:id",
      page: TambahTransferBahanJobMix,
      hak: ["SUPA", "INV_TRN_TJB"],
    },

    // Mapping Item
    {
      exact: true,
      path: "/inventory/transaksi/mapping-item",
      page: MappingItem,
      hak: ["SUPA", "INV_TRN_MIG"],
    },

    // Setup Stok Awal
    {
      exact: true,
      path: "/inventory/transaksi/setup-stok-awal",
      page: SetupStokAwal,
      hak: ["SUPA", "INV_TRN_SSA"],
    },

    // Stock Fisik
    {
      exact: true,
      path: "/inventory/transaksi/stock-fisik",
      page: ListStockFisik,
      hak: ["SUPA", "INV_TRN_STF"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/stock-fisik/tambah/",
      page: TambahStockFisik,
      hak: ["SUPA", "INV_TRN_STF"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/stock-fisik/ubah/:id",
      page: UbahStockFisik,
      hak: ["SUPA", "INV_TRN_STF"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/stock-fisik/detail/:id",
      page: DetailStockFisik,
      hak: ["SUPA", "INV_TRN_STF"],
    },

    // Stock Opname
    {
      exact: true,
      path: "/inventory/transaksi/stock-opname",
      page: ListStockOpname,
      hak: ["SUPA", "INV_TRN_STO"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/stock-opname/stock-fisik/",
      page: ListStockFisikOpname,
      hak: ["SUPA", "INV_TRN_STO"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/stock-opname/stock-fisik/tambah/:id",
      page: TambahStockOpname,
      hak: ["SUPA", "INV_TRN_STO"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/stock-fisik/ubah/:id",
      page: UbahStockFisik,
      hak: ["SUPA", "INV_TRN_STO"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/stock-opname/detail/:id",
      page: DetailStockOpname,
      hak: ["SUPA", "INV_TRN_STO"],
    },

    // Convert Item
    {
      exact: true,
      path: "/inventory/transaksi/convert-item",
      page: ListConvertItem,
      hak: ["SUPA", "INV_TRN_CVI"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/convert-item/tambah/",
      page: TambahConvertItem,
      hak: ["SUPA", "INV_TRN_CVI"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/convert-item/detail/:id",
      page: DetailConvertItem,
      hak: ["SUPA", "INV_TRN_CVI"],
    },

    // Transfer Bahan Work Order
    {
      exact: true,
      path: "/inventory/transaksi/transfer-bahan-work-order",
      page: ListTransferBahanWorkOrder,
      hak: ["SUPA", "INV_TRN_TWB"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/transfer-bahan-work-order/list-permintaan-work-order",
      page: ListPermintaanBahanWorkOrder,
      hak: ["SUPA", "INV_TRN_TWB"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/transfer-bahan-work-order/tambah/:id_work_order",
      page: TambahTransferBahanWorkOrder,
      hak: ["SUPA", "INV_TRN_TWB"],
    },

    // Transfer Bahan Produksi Aset
    {
      exact: true,
      path: "/inventory/transaksi/transfer-bahan-produksi-aset",
      page: TransferBahanProduksiAsetList,
      hak: ["SUPA", "INV_TRN_TBPA"],
    },

    // LAPORAN
    {
      exact: true,
      path: "/inventory/laporan/kartu-stock-material",
      page: KartuStockMaterial,
      hak: ["SUPA", "INV_LAP_KST"],
    },
    {
      exact: true,
      path: "/inventory/laporan/purchase-request",
      page: LaporanPurchaseRequest,
      hak: ["SUPA", "INV_RPT_LPR"],
    },
    {
      exact: true,
      path: "/inventory/laporan/delivery-order",
      page: LaporanDeliveryOrder,
      hak: ["SUPA", "INV_RPT_DO"],
    },
    {
      exact: true,
      path: "/inventory/laporan/stock-update",
      // page: LaporanStockUpdate,
      page: StokTerbaru,
      hak: ["SUPA", "INV_RPT_SU"],
    },

    // Rekapitulasi Nilai Persediaan
    {
      exact: true,
      path: "/inventory/laporan/rekapitulasi-nilai-persediaan",
      page: RekapitulasiNilaiPersediaan,
      hak: ["SUPA", "INV_RPT_RNP"],
    },

    // Laporan Penerimaan Barang
    {
      exact: true,
      path: "/inventory/laporan/penerimaan-barang",
      page: LaporanPenerimaanBarang,
      hak: ["SUPA", "INV_RPT_PBJ"],
    },
    // Monitoring Order
    {
      exact: true,
      path: "/inventory/transaksi/monitoring-order",
      page: MonitoringOrder,
      hak: ["SUPA", "INV_TRN_MTO"],
    },
    // Laporan Surat Jalan
    {
      exact: true,
      path: "/inventory/laporan/surat-jalan",
      page: LaporanSuratJalan,
      hak: ["SUPA", "INV_RPT_SJN"],
    },
    // Laporan Harian Aktivitas Inventori
    {
      exact: true,
      path: "/inventory/laporan/harian-aktivitas-inventori",
      page: LaporanAktivitasHarian,
      hak: ["SUPA", "INV_RPT_ATH"],
    },
  ],
};
