import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { DataStatus, DatePicker, Input, SelectSearch } from "components";
import { DateConvert } from "utilities";
import { SuratJalanApi } from "api";

const FormSection = ({
  formik,
  fetchingStatus,
  formRendered,
  setFormRendered,
  dataPetugas,
  dataArmada,
  getDetail,
}) => {
  const today = DateConvert(new Date()).default;
  const [isNomorLoading, setIsNomorLoading] = useState(false);
  const [isSelectedLoading, setIsSelectedLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const { values, errors, touched, setValues, setFieldValue } = formik;

  const getNomorHandler = (date) => {
    setIsNomorLoading(true);

    SuratJalanApi.getNomor({ tanggal: date })
      .then((no) => {
        const nomor = no.data.data;

        setValues({
          ...values,
          nomor: nomor,
          tanggal: date,
        });
      })
      .catch(() => {
        setValues({
          ...values,
          nomor: "",
          tanggal: "",
        });
        window.alert("Nomor gagal dimuat");
      })
      .finally(() => {
        setIsNomorLoading(false);
      });
  };

  useEffect(() => {
    return () => {
      setFormRendered(formRendered + 1);
      setIsNomorLoading(false);
    };
  }, []);

  return (
    <div>
      <Row>
        <Col md>
          <DatePicker
            label="Tgl. Surat Jalan"
            placeholderText="Pilih tanggal"
            selected={values.tanggal ? new Date(values.tanggal) : ""}
            onChange={(date) => {
              const newDate = DateConvert(date).default;
              getNomorHandler(newDate);
              getDetail(newDate);
            }}
            error={Boolean(errors.tanggal) && touched.tanggal}
            errorText={
              Boolean(errors.tanggal) && touched.tanggal && errors.tanggal
            }
          />
        </Col>
        <Col md>
          <Input
            readOnly
            label="No. Surat Jalan"
            placeholder={
              isNomorLoading
                ? "Memuat nomor . . ."
                : "Pilih tanggal untuk menentukan nomor"
            }
            value={isNomorLoading ? "Memuat nomor . . ." : values.nomor}
            error={Boolean(errors.nomor) && touched.nomor}
            errorText={
              Boolean(errors.nomor) &&
              touched.nomor &&
              errors.petugas_pengiriman
            }
          />
        </Col>
      </Row>
      <Row>
        <Col md>
          <SelectSearch
            label="Petugas Pengiriman"
            placeholder="Pilih petugas pengiriman"
            option={dataPetugas}
            isDisabled={checked}
            defaultValue={dataPetugas.find(
              (val) => val.value === values.petugas_pengiriman
            )}
            onChange={(val) => setFieldValue("petugas_pengiriman", val.value)}
            error={
              Boolean(errors.petugas_pengiriman) && touched.petugas_pengiriman
            }
            errorText={
              Boolean(errors.petugas_pengiriman) &&
              touched.petugas_pengiriman &&
              errors.petugas_pengiriman
            }
          />

          {isSelectedLoading === true ? (
            <DataStatus loading text="Memuat data..." />
          ) : (
            <>
              <Input
                label={checked ? "Petugas Pengiriman Lainya" : ""}
                placeholder="Input Armada Pengiriman Lainnya"
                name="petugas_pengiriman_lain"
                className={!checked ? "d-none" : ""}
                // disabled={checked}
                value={values.petugas_pengiriman_lain}
                onChange={(val) =>
                  setFieldValue("petugas_pengiriman_lain", val.target.value)
                }
              />
              <Input
                label={checked ? "Armada Pengirim Lainnya" : ""}
                placeholder="Input Armada Pengiriman Lainnya"
                name="armada_pengiriman"
                className={!checked ? "d-none" : ""}
                value={values.armada_pengiriman_lain}
                onChange={(val) =>
                  setFieldValue("armada_pengiriman_lain", val.target.value)
                }
              />
            </>
          )}
        </Col>
        <Col md>
          <SelectSearch
            label="Armada Pengiriman"
            placeholder="Pilih armada pengiriman"
            option={dataArmada}
            isDisabled={checked}
            defaultValue={dataArmada.find(
              (val) => val.value === values.armada_pengiriman
            )}
            onChange={(val) => {
              setFieldValue("armada_pengiriman", val.value);
              setFieldValue("nama_kendaraan", val.label);
            }}
            error={Boolean(errors.nama_kendaraan) && touched.nama_kendaraan}
            errorText={
              Boolean(errors.nama_kendaraan) &&
              touched.nama_kendaraan &&
              errors.nama_kendaraan
            }
          />
          <div>
            <input
              type="checkbox"
              defaultChecked={checked}
              onChange={() => {
                setChecked(!checked);
                setFieldValue("armada_pengiriman", null);
                setFieldValue("petugas_pengiriman", null);
                setFieldValue("petugas_pengiriman_lain", null);
                setFieldValue("armada_pengiriman_lain", null);
                setIsSelectedLoading(true);
                setTimeout(() => setIsSelectedLoading(false), 500);
              }}
              className="mr-2"
            />
            <label>Lain - lain</label>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default FormSection;
