import React, { Component, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Axios from "axios";
import {
  CRUDLayout,
  Alert,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  ActionButton,
  Input,
  BackButton,
  DataStatus,
  TextArea,
  Table,
} from "../../../../components";
import { DeliveryOrderApi } from "../../../../api";
import { useHistory } from "react-router-dom";
import { DateConvert, DecimalConvert } from "../../../../utilities";
import { Card, Col, Row } from "react-bootstrap";
import { validateYupSchema } from "formik";

export class ShowData extends Component {
  render() {
    return (
      <div className="d-flex flex-row">
        <div className="text-secondary mb-2 mr-3" style={{ width: 170 }}>
          <span style={{ textTransform: "capitalize" }}>
            {this.props.title}
          </span>
        </div>
        <span>:</span>
        <div
          className="text-dark"
          style={{
            fontWeight: 200,
            paddingLeft: 40,
          }}
        >
          {this.props.text ? this.props.text : "-"}
        </div>
      </div>
    );
  }
}

export class PrintDeliveryOrder extends Component {
  render() {
    let data = this.props.printData;

    return (
      <div style={{ marginTop: 100 }} className="px-4 mx-2">
        <div className="row">
          <div className="col-7">
            <ShowData
              title="Tgl. Purchase Order"
              text={data.tgl_purchase_order}
            />
          </div>
          <div className="col">
            <ShowData
              title="No. Purchase Order"
              text={data.no_purchase_order}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-7">
            <ShowData title="Tgl. Sales Order" text={data.tgl_sales_order} />
          </div>
          <Col className="col">
            <ShowData title="No. Sales Order" text={data.no_sales_order} />
          </Col>
        </div>
        <div className="row">
          <div className="col-7">
            <ShowData
              title="Tgl. Delivery Order"
              text={data.tgl_delivery_order}
            />
          </div>
          <div className="col">
            <ShowData
              title="No. Delivery Order"
              text={data.no_delivery_order}
            />
          </div>
        </div>
        <div className="my-3">
          <Col lg="12 pl-0">
            <ShowData title="Customer" text={data.nama_customer} />
          </Col>
          <Col lg="12 pl-0">
            <ShowData title="Sales" text={data.nama_sales} />
          </Col>
          <Col lg="12 pl-0">
            <ShowData title="Proyek" text={data.nama_proyek} />
          </Col>
        </div>
        <div className="mt-4">List Barang</div>
        <Table>
          <THead>
            <Th>No.</Th>
            <Th>Kode Barang</Th>
            <Th>Nama Barang</Th>
            <Th>Gudang</Th>
            <Th>Qty SO</Th>
            <Th>Satuan</Th>
          </THead>
          <TBody>
            {data?.detail?.map((val, index) => (
              <Tr key={index}>
                <TdFixed>{index + 1}</TdFixed>
                <TdFixed>{val.kode_barang}</TdFixed>
                <TdFixed>{val.nama_barang}</TdFixed>
                <TdFixed>{val.nama_gudang}</TdFixed>
                <TdFixed>{DecimalConvert(val.qty).getWithComa}</TdFixed>
                <TdFixed>{val.nama_satuan}</TdFixed>
              </Tr>
            ))}
          </TBody>
        </Table>
        <div className="row" style={{ marginTop: 70 }}>
          <div className="col-6">
            <label>Catatan :</label>
            <p>{data.catatan_delivery_order}</p>
          </div>
          <div
            className="col-6 d-flex justify-content-center"
            style={{ marginTop: 150 }}
          >
            <label>Tgl. Penerimaan :</label>
            <span>{data.batas_waktu}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-6 d-flex justify-content-center">
            <label>PETUGAS GUDANG</label>
          </div>
          <div className="col-6 d-flex justify-content-center">
            <label>PENGIRIMAN</label>
          </div>
        </div>
        <div className="row" style={{ marginTop: 100 }}>
          <div className="col-2 d-flex justify-content-end">(</div>
          <div className="col-2 d-flex justify-content-center"></div>
          <div className="col-2">)</div>
          <div className="col-2 d-flex justify-content-end">(</div>
          <div className="col-2 d-flex justify-content-center"></div>
          <div className="col-2">)</div>
        </div>
      </div>
    );
  }
}

export default PrintDeliveryOrder;
