import React from 'react'
import {
  Button
} from 'react-bootstrap'

const FilterButton = ({active, className, onClick, noMargin}) => {
  return (
    <div>
      <Button 
        variant="primary"
        onClick={onClick}
        className={`${!noMargin && 'ml-2 mb-2'} px-3 ${className}`}
        >
        Filter: {active ? 'ON' : 'OFF'}
      </Button>
    </div>
  )
}

export default FilterButton
