import { useState, useEffect } from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import ImageViewer from "react-simple-image-viewer";
import {
  DataStatus,
  InfoItemHorizontal,
  InfoItemVertical,
  TBody,
  Table,
  Td,
  Th,
  ThFixed,
  Tr,
} from "components";
import { DateConvert, DecimalConvert } from "utilities";
import { LaporanSuratJalanApi } from "api";
import { Thead } from "@bhawanadevteam/react-core";

const ModalDetailPenerimaanBarang = ({ modalConfig, setModalConfig }) => {
  const [isViewImg, setIsViewImg] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [detail, setDetail] = useState({});
  const { data } = modalConfig;

  const getDetail = (tipe) => {
    setModalLoading(true);

    LaporanSuratJalanApi.single({ id_surat_jalan: modalConfig?.id })
      .then((res) => {
        const detail = res?.data?.data ?? {};
        setDetail(detail);
      })
      .catch(() => {})
      .finally(() => setModalLoading(false));
  };

  useEffect(() => {
    getDetail();

    return () => {
      setModalLoading(false);
    };
  }, []);

  const getStatusPenerimaan = (val) => {
    switch (val.status_surat_jalan) {
      case "terima":
        return (
          <div className="d-flex flex-column">
            <span style={{ color: "#00cc00" }}> SUDAH DITERIMA</span>
            {val?.no_penerimaan_surat_jalan ?? "-"}
          </div>
        );

      case "batal":
        return (
          <div className="d-flex flex-column">
            <span style={{ color: "#ff0000" }}> BATAL DITERIMA</span>
            {val?.no_penerimaan_surat_jalan ?? "-"}
          </div>
        );

      default:
        return <span style={{ color: "#0408ff" }}> BELUM DITERIMA</span>;
    }
  };
  console.log(detail);

  return (
    <Modal
      size="lg"
      show={modalConfig.show && modalConfig.type === "detail"}
      onHide={() => setModalConfig((prev) => ({ ...prev, show: false }))}
    >
      <Modal.Header closeButton>
        <b>Detail Data Surat Jalan</b>
      </Modal.Header>
      <Modal.Body>
        {modalLoading ? (
          <DataStatus text="Memuat data..." loading />
        ) : (
          <>
            <Row>
              <Col md="6">
                <InfoItemHorizontal
                  width={150}
                  label="Tgl. Sales Order"
                  text={DateConvert(new Date(detail.tgl_sales_order)).detail}
                />
                <InfoItemHorizontal
                  width={150}
                  label="No. Sales Order"
                  text={detail.no_sales_order ?? "-"}
                />
                <InfoItemHorizontal
                  width={150}
                  label="Tgl. Delivery Order"
                  text={DateConvert(new Date(detail.tgl_delivery_order)).detail}
                />
                <InfoItemHorizontal
                  width={150}
                  label="No. Delivery Order"
                  text={detail.no_delivery_order ?? "-"}
                />
              </Col>
              <Col md="6">
                <InfoItemHorizontal
                  width={150}
                  label="Customer"
                  text={detail.nama_customer ?? "-"}
                />
                <InfoItemHorizontal
                  width={150}
                  label="Sales"
                  text={detail.nama_sales ?? "-"}
                />
                <InfoItemHorizontal
                  width={150}
                  label="Proyek"
                  text={detail.nama_proyek ?? "-"}
                />
                <InfoItemHorizontal
                  width={150}
                  label="Tgl. Batas Waktu"
                  text={
                    DateConvert(new Date(detail.tgl_penerimaan_surat_jalan))
                      .detail
                  }
                />
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <InfoItemVertical
                  label="Tgl. Surat Jalan"
                  text={DateConvert(new Date(detail.tgl_surat_jalan)).detail}
                />
                <InfoItemVertical
                  label="Petugas Pengiriman"
                  text={detail.nama_supir ?? "-"}
                />
                <InfoItemVertical
                  label="Status Penerimaan"
                  text={getStatusPenerimaan(detail)}
                />
              </Col>
              <Col>
                <InfoItemVertical
                  label="No. Surat Jalan"
                  text={detail.no_surat_jalan ?? "-"}
                />
                <InfoItemVertical
                  label="Armada Pengiriman"
                  text={detail.nama_kendaraan ?? ""}
                />
              </Col>
            </Row>
            <hr />
            <b>List Data Item Surat Jalan</b>
            <Table>
              <Thead>
                <Tr>
                  <ThFixed>No.</ThFixed>
                  <ThFixed>Kode Barang</ThFixed>
                  <Th>Item Barang</Th>
                  <Th>Gudang</Th>
                  <Th>Qty. Surat Jalan</Th>
                  <Th>Satuan</Th>
                </Tr>
              </Thead>
              <TBody>
                {detail?.detail &&
                  detail?.detail?.map((val, ind) => (
                    <Tr key={ind}>
                      <Td>{ind + 1}</Td>
                      <Td>{val.kode_barang ?? "-"}</Td>
                      <Td>{val.nama_barang ?? "-"}</Td>
                      <Td>{val.nama_gudang ?? "-"}</Td>
                      <Td className="text-right">
                        {DecimalConvert(parseFloat(val.qty ?? 0)).getWithComa}
                      </Td>
                      <Td>{val.nama_satuan ?? "-"}</Td>
                    </Tr>
                  ))}
              </TBody>
            </Table>
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="outline-secondary"
          onClick={() => setModalConfig((prev) => ({ ...prev, show: false }))}
        >
          TUTUP
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDetailPenerimaanBarang;
