import Services from "services";

class StockFisikApi {
  get(params) {
    return Services.get("/stock_opname/page", { params });
  }

  getListStockFisik(params) {
    return Services.get("/stock_opname/list_stock_fisik", { params });
  }

  getSingleStockFisik(params) {
    return Services.get("/stock_opname/list_stock_fisik_single", { params });
  }

  getSingle(params) {
    return Services.get("/stock_opname/single", { params });
  }

  getNomor(params) {
    return Services.get("/stock_opname/no_baru", { params });
  }

  getBahan() {
    return Services.get("/stock_fisik/bahan");
  }

  dropdownUnitProduksi() {
    return Services.get("/stock_fisik/unit_produksi");
  }

  dropdownGudang(params) {
    return Services.get("/stock_fisik/gudang", { params });
  }

  create(value) {
    return Services.post("/stock_opname", value);
  }

  update(value) {
    return Services.put("/stock_fisik", value);
  }

  alreadyStockOpname(params) {
    return Services.get("stock_opname/cek", { params });
  }
}

export default new StockFisikApi();
