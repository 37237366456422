import Services from "../../../services";

class BuasoApi {
  page(params) {
    return Services.get('/penerimaan_barang/page', { params });
  }
  search(key) {
    return Services.get(`/penerimaan_barang/page/?q=${key}`);
  }
  pagePO(params) {
    return Services.get('/penerimaan_barang/purchase_order?page=${page}&per_page=${dataLength}&q=${key}', { params })
  }
  searchPO(key) {
    return Services.get(`/penerimaan_barang/purchase_order?q=${key}`);
  }
  getAtribut(tipe) {
    return Services.get(`/penerimaan_barang/dropdown?tipe=${tipe}`);
  }
  getSingle(atribut, value) {
    return Services.get(`/penerimaan_barang/single?${atribut}=${value}`);
  }
  getSinglePO(params) {
    return Services.get(`/penerimaan_barang/detail_purchase_order`, {params});
  }
  getSinglePenerimaan(atribut, value) {
    return Services.get(`/penerimaan_barang/penerimaan_barang?${atribut}=${value}`);
  }
  getNo(tanggal) {
    return Services.get(`/penerimaan_barang/no_baru?tanggal=${tanggal}`);
  }
  create(data) {
    return Services.post("/penerimaan_barang", data);
  }
  update(data) {
    return Services.put("/penerimaan_barang/", data);
  }
  upload(data) {
    return Services.post("/upload/inventori", data);
  }
  vendor() {
    return Services.get('/dropdown/vendor')
  }
  item_bahan() {
    return Services.get('/dropdown/item_bahan')
  }
}

export default new BuasoApi();
