import Services from "../../../services"

class RegItemBahanApi {
  page(params) {
    return Services.get('/bahan/page', { params })
  }
  single(id) {
    return Services.get(`/bahan/single/?id_item_buaso=${id}`)
  }
  getBuaso() {
    return Services.get("/buaso")
  }
  getBahan() {
    return Services.get("/bahan/dropdown")
  }
  getSatuan() {
    return Services.get("/satuan/dropdown")
  }
  getKelompok() {
    return Services.get("/bahan/kelompok?id_buaso=1")
  }
  getJenis(data) {
    return Services.get(`/bahan/jenis?id_kelompok=${data}`)
  }
  getPabrikan() {
    return Services.get("/pabrikan/dropdown")
  }
  generateKodeItem() {
    return Services.get("/bahan/no_baru")
  }
  create(id, data) {
    return Services.post(id === "7" ? "/registrasi_barang_jadi" : "/bahan", data)
  }
  update(id, data) {
    return Services.put(id === "7" ? "/registrasi_barang_jadi" : "/bahan", data)
  }
  delete(id_bahan) {
    return Services.post("/bahan/delete", id_bahan)
  }
  search(key) {
    return Services.get(`bahan/page/?q=${key}`)
  }
  show(id_bahan) {
    return Services.put("/bahan/show", id_bahan)
  }
  hide(id_bahan) {
    return Services.put("/bahan/hide", id_bahan)
  }
  getKonversi(params) {
    return Services.get("/bahan/nilai_konversi", { params })
  }
  cek_nama(params) {
    return Services.get("/registrasi_barang_jadi/cek_nama", { params })
  }
  no_baru(params) {
    return Services.get("/bahan/no_format_baru", { params })
  }
  upload(value) {
    return Services.post(`/upload/inventori_multiple`, value)
  }
}

export default new RegItemBahanApi()
