import { ActionButton, BackButton } from "components";
import { useFormikContext } from "formik";
import React from "react";
import { Modal } from "react-bootstrap";
import { IoWarning } from "react-icons/io5";

export const ModalConfirm = ({ show, setShow }) => {
  const { handleSubmit, isSubmitting } = useFormikContext();
  return (
    <div>
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header closeButton>Tambah Data Stock Opname</Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <IoWarning className="text-danger display-1" />
          </div>
          <div className="text-center">
            <b>
              Apakah Anda yakin ingin meyimpan data Stock Opname ?
              <br /> Data yang telah disimpan tidak dapat diedit lagi
            </b>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-felx justify-content-center">
          <form onSubmit={handleSubmit}>
            <BackButton onClick={() => setShow(false)} />
            <ActionButton text="Simpan" type="submit" loading={isSubmitting} />
          </form>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
