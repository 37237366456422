import Services from "../../../services";

class DeliveryOrderApi {
  page(params) {
    return Services.get('/delivery_order/page', { params })
  }
  sales_order_page(params) {
    return Services.get('/sales_order/page', { params })
  }
  search(key) {
    return Services.get(`/delivery_order/page/?q=${key}`);
  }
  searchSales(key) {
    return Services.get(`/sales_order/page/?q=${key}`);
  }
  getForm(id) {
    return Services.get(`/sales_order/single?id_sales_order=${id}`);
  }
  getFormData(params) {
    return Services.get('/sales_order/single', { params });
  }
  getFormEdit(id) {
    return Services.get(`/delivery_order/single?id_delivery_order=${id}`);
  }
  getStatus(noRef) {
    return Services.get(`/status_sales_order/single?no_delivery_order=${noRef}`);
  }
  getKode(value) {
    return Services.get(`/delivery_order/no_baru?tanggal=${value}`);
  }
  getProgress(noRef) {
    return Services.get(`/status_sales_order/single?no_delivery_order=${noRef}`);
  }
  create(data) {
    return Services.post("/delivery_order", data);
  }
  update(data) {
    return Services.put("/delivery_order", data);
  }
  delete(id) {
    return Services.post("/delivery_order/delete", id);
  }
  dropdown(value) {
    return Services.get(`/dropdown/${value}`);
  }
}

export default new DeliveryOrderApi();
