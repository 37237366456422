// React
import React, { useState, useEffect } from 'react';

// Router
import { useHistory, useLocation } from 'react-router-dom';

// Components
import {
  CRUDLayout,
  InputSearch,
  CreateButton,
  TdFixed,
  DataStatus,
  Tr,
  ReadButton,
  Alert,
  Pagination,
  Th,
  Td,
  THead,
  TBody,
  ThFixed,
  FilterButton,
} from 'components';
import { PageNumber, DateConvert, DecimalConvert } from 'utilities';

// API
import { TransferBahanJobMixApi } from 'api';

// View Components
import { ModalFilter, TransferModal } from './components';

const TransferBahanJobMix = ({ setNavbarTitle }) => {
  // USE HISTORY
  const history = useHistory();
  const location = useLocation();

  // Variables
  const title = 'Transfer Bahan Produksi';
  const filter = location.state?.filter ?? {};

  // indikator pemanggilan data sedang dimuat di server (loading)
  const [isLoading, setIsLoading] = useState(false);

  // data jenis
  const [data, setData] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [totalData, setTotalData] = useState('');
  const [modalFilter, setModalFilter] = useState(false);
  const [dataFilter, setDataFilter] = useState({
    active: filter.pre?.active,
    page: filter.pre?.page ?? 1,
    per_page: filter.pre?.per_page ?? 10,
    q: filter.pre?.q ?? '',
    tgl_transfer_produksi_bahan_awal:
      filter.pre?.tgl_transfer_produksi_bahan_awal,
    tgl_transfer_produksi_bahan_akhir:
      filter.pre?.tgl_transfer_produksi_bahan_akhir,
    tgl_permintaan_produksi_bahan_awal:
      filter.pre?.tgl_permintaan_produksi_bahan_awal,
    tgl_permintaan_produksi_bahan_akhir:
      filter.pre?.tgl_permintaan_produksi_bahan_akhir,
    id_item_buaso: filter.pre?.id_item_buaso,
    id_gudang_asal: filter.pre?.id_gudang_asal,
    id_gudang_tujuan: filter.pre?.id_gudang_tujuan,
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });
  const [modal, setModal] = useState(false);
  const [dataModal, setDataModal] = useState({});
  const [searchTime, setSearchTime] = useState(null);
  // request data dari server
  const getData = () => {
    setIsLoading(true);

    const filterData = { ...dataFilter };
    delete filterData.active;

    TransferBahanJobMixApi.page(filterData)
      .then((res) => {
        setData(res.data.data);
        setTotalPage(res.data.total_page);
        setTotalData(res.data.data_count);
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsLoading(false));
  };

  //menampilkan alert
  const [showAlert, setShowAlert] = useState(false);

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const onInputSearch = (text) => {
    const key = text.target.value;
    if (searchTime) clearTimeout(searchTime);
    setSearchTime(
      setTimeout(() => {
        setDataFilter({
          ...dataFilter,
          page: '1',
          q: key,
        });
        setAlertConfig({
          show: key ? true : false,
          variant: 'primary',
          text: 'Hasil dari pencarian: ' + key,
        });
        setShowAlert(true);
      }, 750),
    );
  };

  useEffect(() => {
    setNavbarTitle(title);
    checkAlert();

    // eslint-disable-next-line
  }, [setNavbarTitle]);

  useEffect(() => {
    getData();

    // eslint-disable-next-line
  }, [dataFilter]);

  const toggleModal = () => setModal(!modal);
  const toggleModalFilter = () => setModalFilter(!modalFilter);

  // Tabel
  const Table = () => {
    return (
      <>
        <b>List Data {title}</b>
        <CRUDLayout.Table>
          <THead>
            <Tr>
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <ThFixed>Informasi Transfer Bahan Produksi</ThFixed>
              <ThFixed>Informasi Permintaan Produksi</ThFixed>
              <Th>Item Bahan</Th>
              <ThFixed>Qty. Transfer</ThFixed>
              <Th>Gudang Asal</Th>
              <Th>Gudang Tujuan</Th>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => (
              <Tr key={index}>
                <TdFixed>
                  {PageNumber(dataFilter.page, dataFilter.per_page, index)}
                </TdFixed>
                <TdFixed>
                  <ReadButton
                    size="sm"
                    onClick={() => {
                      setDataModal(val);
                      toggleModal();
                    }}
                  />
                </TdFixed>
                <Td>
                  <div>
                    {val.tgl_transfer_produksi_bahan
                      ? DateConvert(new Date(val.tgl_transfer_produksi_bahan))
                          .defaultDMY
                      : '-'}
                  </div>
                  <div>{val.no_transfer_produksi_bahan ?? '-'}</div>
                </Td>
                <Td>
                  <div>
                    {val.tgl_permintaan_produksi_bahan
                      ? DateConvert(new Date(val.tgl_permintaan_produksi_bahan))
                          .defaultDMY
                      : '-'}
                  </div>
                  <div>{val.no_permintaan_produksi_bahan ?? '-'}</div>
                </Td>
                <Td>
                  {val.nama_item ?? '-'} [{val?.nama_varian ?? ''}]
                </Td>
                <Td className="text-right">{`${
                  DecimalConvert(val.qty_transfer ?? 0).getWithComa
                } ${val.kode_satuan ?? ''}`}</Td>
                <Td>{val.nama_gudang_asal ?? '-'}</Td>
                <Td>{val.nama_gudang_tujuan ?? '-'}</Td>
              </Tr>
            ))}
          </TBody>
        </CRUDLayout.Table>
        <Pagination
          dataLength={dataFilter.per_page}
          dataPage={
            totalData <= 10
              ? data.length
              : data.map((_res, index) => {
                  if (index === data.length - 1) {
                    return PageNumber(
                      dataFilter.page,
                      dataFilter.per_page,
                      index,
                    );
                  } else {
                    return null;
                  }
                })
          }
          dataNumber={data.map((_res, index) => {
            if (index === 0) {
              return PageNumber(dataFilter.page, dataFilter.per_page, index);
            } else {
              return null;
            }
          })}
          dataCount={totalData}
          onDataLengthChange={(e) => {
            setDataFilter({
              ...dataFilter,
              per_page: e.target.value,
              page: 1,
            });
          }}
          currentPage={dataFilter.page}
          totalPage={totalPage}
          onPaginationChange={({ selected }) =>
            setDataFilter({
              ...dataFilter,
              page: selected + 1,
            })
          }
        />
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch onChange={onInputSearch} />

            <FilterButton
              active={dataFilter?.active}
              onClick={toggleModalFilter}
            />
          </div>
        </CRUDLayout.HeadSearchSection>

        <CRUDLayout.HeadButtonSection>
          {/* <ExportButton /> */}

          {/*
          <CreateButton
            onClick={() => {
              history.push(
                "/inventory/transaksi/transfer-bahan-job-mix/list-permintaan-bahan",
                {
                  filter: {
                    ...filter,
                    pre: dataFilter,
                  },
                }
              );
            }}
          />
          */}
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* ALert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => {
          setAlertConfig({
            ...alertConfig,
            show: false,
          });
          // CLEAR HISTORY LOCATION
          history.replace('/inventory/transaksi/transfer-bahan-job-mix', {
            registerAlertConfig: undefined,
          });
        }}
      />

      {/* Table Section */}
      {isLoading ? (
        <DataStatus loading={true} text="Memuat data..." />
      ) : data.length ? (
        <Table />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}
      {modal && (
        <TransferModal toggle={toggleModal} data={dataModal} title={title} />
      )}
      {modalFilter && (
        <ModalFilter
          toggle={toggleModalFilter}
          data={dataFilter}
          setData={setDataFilter}
          buaso="bahan"
        />
      )}
    </CRUDLayout>
  );
};

export default TransferBahanJobMix;
