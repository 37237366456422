// Components
import {
  DatePicker,
  InfoItemVertical,
  Input,
  InputQtyDecimal,
  SelectSearch,
  TextArea,
} from "components";
import { Col, Row } from "react-bootstrap";
import { DateConvert, DecimalConvert } from "utilities";

// Form
import { useFormikContext } from "formik";
import { TransferBahanJobMixApi } from "api";

const FormSection = ({
  readOnly,
  buaso,
  dropdown,
  getGudang,
  dataGudang,
  dataKaryawan,
}) => {
  // Variables
  const { values, setFieldValue, setValues, handleChange, errors, touched } =
    useFormikContext();

  const getDataNomor = (value) => {
    TransferBahanJobMixApi.no_baru(value).then((res) => {
      setFieldValue("no_transfer_produksi_bahan", res.data.data);
    });
  };

  if (readOnly) {
    return (
      <>
        <Row>
          <Col md={6}>
            <InfoItemVertical
              className="text-capitalize"
              label={`Tgl. Transfer ${buaso} Produksi`}
              text={
                values[`tgl_transfer_produksi_${buaso}`]
                  ? DateConvert(
                      new Date(values[`tgl_transfer_produksi_${buaso}`])
                    ).detail
                  : "-"
              }
            />
          </Col>

          <Col md={6}>
            <InfoItemVertical
              className="text-capitalize"
              label={`No. Transfer ${buaso} Produksi`}
              text={values[`no_transfer_produksi_${buaso}`] ?? "-"}
            />
          </Col>

          <Col md={6}>
            <InfoItemVertical
              label="Gudang Asal"
              text={values.nama_gudang_asal ?? "-"}
            />
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <InfoItemVertical
              label="Gudang Tujuan"
              text={values.nama_gudang_tujuan ?? "-"}
            />
          </Col>

          <Col md={6}>
            <InfoItemVertical
              label="Qty. Transfer"
              text={`${DecimalConvert(values.qty_transfer ?? 0).getWithComa} ${
                values.kode_satuan ?? ""
              }`}
            />
          </Col>

          <Col md={6}>
            <InfoItemVertical
              label="Diserahkan Oleh"
              text={values.nama_karyawan ?? "-"}
            />
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <InfoItemVertical
              label="Keterangan Transfer"
              text={values.keterangan_transfer ?? "-"}
            />
          </Col>
        </Row>
      </>
    );
  } else {
    return (
      <Row>
        <Col md={6}>
          <DatePicker
            label="Tgl. Transfer Bahan Produksi"
            selected={
              values.tgl_transfer_produksi_bahan
                ? new Date(values.tgl_transfer_produksi_bahan)
                : ""
            }
            placeholderText="Pilih "
            onChange={(date) => {
              const tanggal = DateConvert(new Date(date)).default;

              setFieldValue("tgl_transfer_produksi_bahan", tanggal);
              getDataNomor(tanggal);
              getGudang(tanggal);
            }}
            error={
              Boolean(errors.tgl_transfer_produksi_bahan) &&
              touched.tgl_transfer_produksi_bahan
            }
            errorText={
              Boolean(errors.tgl_transfer_produksi_bahan) &&
              touched.tgl_transfer_produksi_bahan &&
              errors.tgl_transfer_produksi_bahan
            }
          />
        </Col>

        <Col md={6}>
          <Input
            label="No. Transfer Bahan Produksi"
            placeholder="No. Transfer Bahan Produksi"
            value={values.no_transfer_produksi_bahan}
            readOnly
          />
        </Col>

        <Col md={8}>
          <SelectSearch
            label="Gudang Asal"
            placeholder="Pilih Gudang Asal"
            defaultValue={dataGudang.find(
              (e) => e.value === values.id_gudang_asal
            )}
            onChange={(val) =>
              setValues({
                ...values,
                id_gudang_asal: val.value,
                stok_gudang: val.jumlah,
                nama_satuan: val.nama_satuan,
              })
            }
            option={dataGudang}
            error={Boolean(errors.id_gudang_asal) && touched.id_gudang_asal}
            errorText={
              Boolean(errors.id_gudang_asal) &&
              touched.id_gudang_asal &&
              errors.id_gudang_asal
            }
          />
        </Col>

        <Col md={4}>
          <Input
            label="Stok Gudang"
            placeholder="Stok Gudang"
            value={
              values.stok_gudang &&
              `${values.stok_gudang} ${values.nama_satuan}`
            }
            readOnly
          />
        </Col>

        <Col md={8}>
          <SelectSearch
            label="Gudang Tujuan"
            placeholder="Pilih Gudang Tujuan"
            defaultValue={dataGudang.find(
              (e) => e.value === values.id_gudang_tujuan
            )}
            onChange={(val) => setFieldValue("id_gudang_tujuan", val.value)}
            option={dataGudang}
            error={Boolean(errors.id_gudang_tujuan) && touched.id_gudang_tujuan}
            errorText={
              Boolean(errors.id_gudang_tujuan) &&
              touched.id_gudang_tujuan &&
              errors.id_gudang_tujuan
            }
          />
        </Col>

        <Col md={4}>
          {/* <Input
            label="Qty. Transfer"
            type="text"
            name="qty_transfer"
            placeholder="Qty. Transfer"
            value={values.qty_transfer}
            onChange={handleChange}
            error={Boolean(errors.qty_transfer) && touched.qty_transfer}
            errorText={
              Boolean(errors.qty_transfer) &&
              touched.qty_transfer &&
              errors.qty_transfer
            }
          /> */}
          <InputQtyDecimal
            label="Qty. Transfer"
            value={values.qty_transfer}
            onChange={(e) => setFieldValue("qty_transfer", e)}
            error={Boolean(errors.qty_transfer) && touched.qty_transfer}
            errorText={
              Boolean(errors.qty_transfer) &&
              touched.qty_transfer &&
              errors.qty_transfer
            }
          />
        </Col>

        <Col md={12}>
          <SelectSearch
            label="Diserahkan Oleh"
            placeholder="Pilih Karyawan"
            defaultValue={dataKaryawan.find(
              (e) => e.value === values.id_karyawan
            )}
            onChange={(val) => setFieldValue("id_karyawan", val.value)}
            option={dataKaryawan}
            error={Boolean(errors.id_karyawan) && touched.id_karyawan}
            errorText={
              Boolean(errors.id_karyawan) &&
              touched.id_karyawan &&
              errors.id_karyawan
            }
          />
        </Col>

        <Col md={12}>
          <TextArea
            label="Keterangan Transfer"
            name="keterangan_transfer"
            rows={4}
            placeholder="Keterangan Transfer"
            value={values.keterangan_transfer}
            onChange={handleChange}
            error={
              Boolean(errors.keterangan_transfer) && touched.keterangan_transfer
            }
            errorText={
              Boolean(errors.keterangan_transfer) &&
              touched.keterangan_transfer &&
              errors.keterangan_transfer
            }
          />
        </Col>
      </Row>
    );
  }
};

export default FormSection;
