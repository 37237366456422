import React, { useState } from "react";
import { DecimalConvert } from "utilities";

export const CollapesItem = ({ data }) => {
  const [isReadMoreClick, setisReadMoreClick] = useState(false);

  return (
    <>
      <ul className="pl-3 mb-1" style={{ fontSize: "12px" }}>
        {data.map((item, index) => {
          if (isReadMoreClick) {
            return (
              <li key={index} index={index}>
                {`${item.nama_item ?? "-"} - ${
                  DecimalConvert(item.qty_campuran).getWithComa ?? "0"
                } ${item.satuan_pakai ?? "-"}`}
              </li>
            );
          } else {
            return (
              index <= 2 && (
                <li key={index} index={index}>
                  {`${item.nama_item ?? "-"} - ${
                    DecimalConvert(item.qty_campuran).getWithComa ?? "0"
                  } ${item.satuan_pakai ?? "-"}`}
                </li>
              )
            );
          }
        })}
      </ul>
      {data.length <= 3 ? (
        ""
      ) : (
        <div
          className="text-primary"
          style={{
            cursor: "pointer",
            textDecoration: "underline",
            // fontWeight: "bold",
          }}
          onClick={() => {
            setisReadMoreClick((prev) => !prev);
          }}
        >
          {isReadMoreClick ? "Sembunyikan" : "Selengkapnya..."}
        </div>
      )}
    </>
  );
};
