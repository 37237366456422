import Services from "../../../services";

class PurchaseRequestApi {
  getPage(params) {
    return Services.get(`/purchase_request/page`, { params });
  }

  single(id) {
    return Services.get("/purchase_request/single?id_purchase_request=" + id);
  }

  getNoSurat(id) {
    return Services.get(`/purchase_request/no_baru?tanggal=${id}`);
  }

  getKelompok() {
    return Services.get("/purchase_request/kelompok_bahan?id_buaso=1");
  }

  getProyek() {
    return Services.get("/purchase_request/proyek");
  }

  getJenis(id) {
    return Services.get(`/purchase_request/jenis_bahan?id_kelompok=${id}`);
  }

  getItem(id) {
    return Services.get(`/purchase_request/item_bahan?id_jenis=${id}`);
  }

  getProgress(id) {
    return Services.get(`purchase_request/progress?id_purchase_request=${id}`);
  }

  search(key) {
    return Services.get(`/purchase_request/page/?q=${key}`);
  }

  create(data) {
    return Services.post("/purchase_request", data);
  }

  update(data) {
    return Services.put("/purchase_request", data);
  }

  delete(id) {
    return Services.post("/purchase_request/delete", id);
  }

  dropdownItemBarang() {
    return Services.get("/purchase_request/item_barang");
  }

  export(params) {
    return Services.get("/laporan_purchase_request/export", { params });
  }
  exportPdf(params) {
    return Services.get("/purchase_request/export", { params });
  }
}

export default new PurchaseRequestApi();
