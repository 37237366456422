import React from 'react'
import './Spinner.css'

function Spiner({ style }) {
  return (
    <div style={style} className="lds-spinner">
      <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
    </div>
  )
}

export default Spiner