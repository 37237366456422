// React
import React from "react";

// Form
import { useFormikContext } from "formik";

// Components
import { DatePicker, FilterModal } from "components";
import { Select } from "components2";

// API
import { useQuery } from "react-query";
import { DateConvert } from "utilities";
import TransferBahanProduksiAsetApi from "../TransferBahanProduksiAsetApi";

export const ModalFilter = ({ toggle }) => {
  const { values, handleSubmit, setValues } = useFormikContext();

  const { data: dropdownGudang, isFetching: loadingDropdownGudang } = useQuery(
    ["gudang", "dropdown"],
    () => TransferBahanProduksiAsetApi.dropdownGudang()
  );

  const { data: dropdownItem, isFetching: loadingDropdownItem } = useQuery(
    ["item", "dropdown"],
    () => TransferBahanProduksiAsetApi.dropdownItem()
  );

  // HANDLE CHANGE
  const onResetButtonClick = (setValues) => {
    const myPromise = new Promise((resolve) => {
      setValues({
        tgl_transfer_produksi_aset_bahan_mulai: undefined,
        tgl_transfer_produksi_aset_bahan_selesai: undefined,
        tgl_permintaan_produksi_aset_bahan_mulai: undefined,
        tgl_permintaan_produksi_aset_bahan_selesai: undefined,
        id_gudang_tujuan: undefined,
        id_gudang_asal: undefined,
        id_item_bahan: undefined,
        active: false,
      });

      setTimeout(() => resolve(), 300);
    });

    myPromise.then(() => {});
  };

  const onTanggalChange = (
    dates,
    values,
    setValues,
    value_awal,
    value_akhir
  ) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      [value_awal]: startDate,
      [value_akhir]: endDate,
    });
  };

  return (
    <FilterModal
      isToggle
      toggleModal={toggle}
      onResetButtonClick={() => onResetButtonClick(setValues)}
      onFilterButtonClick={handleSubmit}
    >
      <DatePicker
        selectsRange
        label="Tgl. Transfer Bahan Produksi Aset"
        placeholderText="Pilih tanggal"
        startDate={
          values?.tgl_transfer_produksi_aset_bahan_mulai
            ? new Date(values?.tgl_transfer_produksi_aset_bahan_mulai)
            : ""
        }
        endDate={
          values?.tgl_transfer_produksi_aset_bahan_selesai
            ? new Date(values?.tgl_transfer_produksi_aset_bahan_selesai)
            : ""
        }
        onChange={(dates) =>
          onTanggalChange(
            dates,
            values,
            setValues,
            "tgl_transfer_produksi_aset_bahan_mulai",
            "tgl_transfer_produksi_aset_bahan_selesai"
          )
        }
        monthsShown={2}
      />

      <DatePicker
        selectsRange
        label="Tgl. Permintaan Produksi Aset"
        placeholderText="Pilih tanggal"
        startDate={
          values?.tgl_permintaan_produksi_aset_bahan_mulai
            ? new Date(values?.tgl_permintaan_produksi_aset_bahan_mulai)
            : ""
        }
        endDate={
          values?.tgl_permintaan_produksi_aset_bahan_selesai
            ? new Date(values?.tgl_permintaan_produksi_aset_bahan_selesai)
            : ""
        }
        onChange={(dates) =>
          onTanggalChange(
            dates,
            values,
            setValues,
            "tgl_permintaan_produksi_aset_bahan_mulai",
            "tgl_permintaan_produksi_aset_bahan_selesai"
          )
        }
        monthsShown={2}
      />

      <Select
        label="Item Bahan"
        placeholder="Pilih item bahan"
        name="id_item_bahan"
        options={dropdownItem ?? []}
        loading={loadingDropdownItem}
        onChange={async (val) =>
          setValues({
            ...values,
            id_item_bahan: val.value,
          })
        }
        defaultValue={dropdownItem?.find(
          (val) => val.value === values.id_item_bahan
        )}
      />

      <Select
        label="Gudang Asal"
        placeholder="Pilih gudang"
        name="id_gudang_asel"
        options={dropdownGudang ?? []}
        loading={loadingDropdownGudang}
        onChange={async (val) =>
          setValues({
            ...values,
            id_gudang_asal: val.value,
          })
        }
        defaultValue={dropdownGudang?.find(
          (val) => val.value === values.id_gudang_asal
        )}
      />

      <Select
        label="Gudang Tujuan"
        placeholder="Pilih gudang"
        name="id_kelompok"
        options={dropdownGudang ?? []}
        loading={loadingDropdownGudang}
        onChange={async (val) =>
          setValues({
            ...values,
            id_gudang_tujuan: val.value,
          })
        }
        defaultValue={dropdownGudang?.find(
          (val) => val.value === values.id_gudang_tujuan
        )}
      />
    </FilterModal>
  );
};
