import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { FilterModal, DatePicker, SelectSearch } from "components";
import { DateConvert } from "utilities";
import Axios from "axios";
import { SuratJalanApi } from "api";

const ModalFilter = ({ show, setShow, data, setData }) => {
  // STATE DATA
  const [dataCustomer, setDataCustomer] = useState([
    { label: "Semua", value: undefined },
  ]);
  const [dataSales, setDataSales] = useState([
    { label: "Semua", value: undefined },
  ]);
  const [dataGudang, setDataGudang] = useState([
    { label: "Semua", value: undefined },
  ]);
  const [loading, setLoading] = useState(true);
  // REQUSET DATA SERVER
  const getDataDropdown = () => {
    Axios.all([
      SuratJalanApi.dropdownCustomer(),
      SuratJalanApi.dropdownSales(),
      SuratJalanApi.dropdownGudang(),
    ])
      .then(
        Axios.spread((customer, sales, gudang) => {
          const mapDataCustomer = customer?.data?.data
            ? customer.data.data.map((val) => ({
                label: val?.nama_customer,
                value: val?.id_customer,
              }))
            : [];
          const mapDataSales = sales?.data?.data
            ? sales.data.data.map((val) => ({
                label: val?.nama_karyawan,
                value: val?.id_karyawan,
              }))
            : [];
          const mapDataGudang = gudang?.data?.data
            ? gudang.data.data.map((val) => ({
                label: val?.nama_gudang,
                value: val?.id_gudang,
              }))
            : [];

          setDataCustomer([...dataCustomer, ...mapDataCustomer]);
          setDataSales([...dataSales, ...mapDataSales]);
          setDataGudang([...dataGudang, ...mapDataGudang]);
        })
      )
      .finally(() => setLoading(false));
  };
  // USE EFFECT ALL DROPDOWN
  useEffect(() => {
    getDataDropdown();
  }, []);
  // FORM VALUES
  const formInitialValues = {
    tgl_delivery_order_mulai: data?.filter?.tgl_delivery_order_mulai,
    tgl_delivery_order_selesai: data?.filter?.tgl_delivery_order_selesai,
    tgl_sales_order_mulai: data?.filter?.tgl_sales_order_mulai,
    tgl_sales_order_selesai: data?.filter?.tgl_sales_order_selesai,
    tgl_batas_waktu_mulai: data?.filter?.tgl_batas_waktu_mulai,
    tgl_batas_waktu_selesai: data?.filter?.tgl_batas_waktu_selesai,
    customer: data?.filter?.customer,
    sales: data?.filter?.sales,
    gudang: data?.filter?.gudang,
  };
  const formSubmitHandler = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setData({
        filter: {
          ...values,
          active: true,
        },
        pagination: {
          ...data.pagination,
          page: 1,
        },
      });
    } else {
      setData({
        ...data,
        filter: {
          ...values,
          active: false,
        },
      });
    }

    setShow(false);
  };
  // HANDLE CHANGE
  const onTanggalDeliveryOrderChange = (dates, values, setValues) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      tgl_delivery_order_mulai: startDate,
      tgl_delivery_order_selesai: endDate,
    });
  };

  const onTanggaleSalesOrderChange = (dates, values, setValues) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      tgl_sales_order_mulai: startDate,
      tgl_sales_order_selesai: endDate,
    });
  };
  const onTanggalBatasWaktuChange = (dates, values, setValues) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      tgl_batas_waktu_mulai: startDate,
      tgl_batas_waktu_selesai: endDate,
    });
  };
  const onResetButtonClick = (values, setValues) => {
    setValues({
      tgl_delivery_order_mulai: undefined,
      tgl_delivery_order_selesai: undefined,
      tgl_sales_order_mulai: undefined,
      tgl_sales_order_selesai: undefined,
      tgl_batas_waktu_mulai: undefined,
      tgl_batas_waktu_selesai: undefined,
      customer: undefined,
      sales: undefined,
      gudang: undefined,
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={formSubmitHandler}
    >
      {({ values, handleSubmit, setFieldValue, setValues }) => (
        <FilterModal
          show={show}
          setShow={setShow}
          onResetButtonClick={() => onResetButtonClick(values, setValues)}
          onFilterButtonClick={handleSubmit}
        >
          <DatePicker
            selectsRange
            label="Tgl. Delivery Order"
            placeholderText="Pilih Tanggal Delivery Order"
            startDate={
              values.tgl_delivery_order_mulai
                ? new Date(values.tgl_delivery_order_mulai)
                : ""
            }
            endDate={
              values.tgl_delivery_order_selesai
                ? new Date(values.tgl_delivery_order_selesai)
                : ""
            }
            onChange={(dates) =>
              onTanggalDeliveryOrderChange(dates, values, setValues)
            }
            monthsShown={2}
          />

          <DatePicker
            selectsRange
            label="Tgl. Sales Order"
            placeholderText="Pilih Tanggal Sales Order"
            startDate={
              values.tgl_sales_order_mulai
                ? new Date(values.tgl_sales_order_mulai)
                : ""
            }
            endDate={
              values.tgl_sales_order_selesai
                ? new Date(values.tgl_sales_order_selesai)
                : ""
            }
            onChange={(dates) =>
              onTanggaleSalesOrderChange(dates, values, setValues)
            }
            monthsShown={2}
          />

          <DatePicker
            selectsRange
            label="Tgl. Batas Waktu"
            placeholderText="Pilih Tanggal Batas Waktu"
            startDate={
              values.tgl_batas_waktu_mulai
                ? new Date(values.tgl_batas_waktu_mulai)
                : ""
            }
            endDate={
              values.tgl_batas_waktu_selesai
                ? new Date(values.tgl_batas_waktu_selesai)
                : ""
            }
            onChange={(dates) =>
              onTanggalBatasWaktuChange(dates, values, setValues)
            }
            monthsShown={2}
          />
          <SelectSearch
            key={values.customer}
            label="Customer"
            placeholder="Pilih Customer"
            defaultValue={dataCustomer.find(
              (val) => val.value === values.customer
            )}
            option={dataCustomer}
            onChange={(val) => setFieldValue("customer", val.value)}
            loading={loading}
          />
          <SelectSearch
            key={values.sales}
            label="Sales"
            placeholder="Pilih Sales"
            defaultValue={dataSales.find((val) => val.value === values.sales)}
            option={dataSales}
            onChange={(val) => setFieldValue("sales", val.value)}
            loading={loading}
          />
          {/* <SelectSearch
                        key={values.gudang}
                        label="Gudang"
                        placeholder="Pilih Gudang"
                        defaultValue={dataGudang.find(val => val.value === values.gudang)}
                        option={dataGudang}
                        onChange={val => setFieldValue('gudang', val.value)}
                        loading={loading}
                    /> */}
        </FilterModal>
      )}
    </Formik>
  );
};
export default ModalFilter;
