// Form
import { DatePicker, InfoItemVertical, Input, NumberFormat } from "components";
import { useFormikContext } from "formik";

// Components
import { Card, Col, Row } from "react-bootstrap";
import { DateConvert, DecimalConvert } from "utilities";

// View Functions
import { generateStatusApproval, mappingQty } from "../../functions";

const InfoFormSection = ({ data, isCreate, readOnly }) => {
  // Hooks
  const { values, errors, touched, setFieldValue, setValues } =
    useFormikContext();

  const setQtyAnalisa = (value) => {
    setValues({
      ...values,
      qty_jobmix: value,
      analisa_bahan: mappingQty(values, "analisa_bahan", value),
      analisa_upah: mappingQty(values, "analisa_upah", value),
      analisa_alat_mesin: mappingQty(values, "analisa_alat_mesin", value),
      analisa_subcont: mappingQty(values, "analisa_subcont", value),
      analisa_overhead: mappingQty(values, "analisa_overhead", value),
    });
  };

  const ListItem = ({ label, text }) => (
    <tr>
      <td style={{ fontSize: 14 }} className="text-nowrap">
        {label}
      </td>
      <td style={{ fontSize: 14 }} className="px-1">
        :
      </td>
      <td style={{ fontSize: 14 }}>{text}</td>
    </tr>
  );

  return (
    <>
      <b>Informasi Umum</b>

      <Card>
        <Card.Body>
          <Row>
            <Col md>
              <table>
                <tbody>
                  <ListItem
                    label="Tgl. Sales Order"
                    text={
                      data.tgl_sales_order
                        ? DateConvert(new Date(data.tgl_sales_order)).detail
                        : "-"
                    }
                  />
                  <ListItem
                    label="No. Sales Order"
                    text={data.no_sales_order ?? "-"}
                  />
                  <ListItem
                    label="Barang Jadi"
                    text={
                      isCreate ? data.nama_barang ?? "-" : data.nama_item ?? "-"
                    }
                  />
                  <ListItem label="Customer" text={data.nama_customer ?? "-"} />
                  <ListItem label="Proyek" text={data.nama_proyek ?? "-"} />
                </tbody>
              </table>
            </Col>

            <Col md>
              <table>
                <tbody>
                  <ListItem
                    label="Qty. Sales Order"
                    text={`${
                      data.qty_item
                        ? DecimalConvert(data.qty_item).getWithComa
                        : "-"
                    } ${data.kode_satuan ?? ""}`}
                  />
                  <ListItem
                    label="Qty. Sedang Diproduksi"
                    text={`${
                      data.qty_sedang_diproduksi
                        ? DecimalConvert(data.qty_sedang_diproduksi).getWithComa
                        : "-"
                    } ${data.kode_satuan ?? ""}`}
                  />
                  <ListItem
                    label="Qty. Telah Direalisasi"
                    text={
                      isCreate
                        ? `${
                            data.qty_realisasi
                              ? DecimalConvert(data.qty_realisasi).getWithComa
                              : "-"
                          } ${data.kode_satuan ?? ""}`
                        : `${
                            data.qty_realisasi
                              ? DecimalConvert(data.qty_realisasi).getWithComa
                              : "-"
                          } ${data.kode_satuan ?? ""}`
                    }
                  />
                </tbody>
              </table>
            </Col>
          </Row>

          <hr />

          {readOnly ? (
            <Row>
              <Col md={6} lg>
                <InfoItemVertical
                  label="Tgl. Produksi"
                  text={DateConvert(new Date(data.tgl_jobmix)).detail}
                />
              </Col>
              <Col md={6} lg>
                <InfoItemVertical label="No. Produksi" text={data.no_jobmix} />
              </Col>
              <Col md={6} lg>
                <InfoItemVertical
                  label="Qty. Produksi"
                  text={`${DecimalConvert(data.qty_jobmix).getWithComa} ${
                    data.kode_satuan
                  }`}
                />
              </Col>
              <Col md={6} lg>
                <InfoItemVertical
                  label="Status Progress"
                  text={
                    <div className={generateStatusApproval(data).textColor}>
                      {generateStatusApproval(data).label}
                    </div>
                  }
                />
              </Col>
            </Row>
          ) : (
            <Row>
              <Col md={6} lg>
                <DatePicker
                  label="Tgl. Produksi"
                  placeholderText="Pilih tanggal"
                  selected={
                    values.tgl_jobmix ? new Date(values.tgl_jobmix) : ""
                  }
                  onChange={(date) =>
                    setFieldValue("tgl_jobmix", DateConvert(date).default)
                  }
                  error={Boolean(errors.tgl_jobmix && touched.tgl_jobmix)}
                  errorText={
                    Boolean(errors.tgl_jobmix && touched.tgl_jobmix) &&
                    errors.tgl_jobmix
                  }
                />
              </Col>
              <Col md={6} lg>
                <Input
                  readOnly
                  label="Nomor Produksi"
                  placeholder="Pilih tanggal untuk menentukan nomor"
                  value={values.nomor}
                  error={Boolean(errors.nomor && touched.nomor)}
                  errorText={
                    Boolean(errors.nomor && touched.nomor) && errors.nomor
                  }
                />
              </Col>
              <Col md={6} lg>
                <NumberFormat
                  label="Qty. Produksi"
                  placeholder="Qty. Produksi"
                  value={values.qty_jobmix ? Math.round(values.qty_jobmix) : ""}
                  // isAllowed={({ value, floatValue }) =>
                  //   data.qty_item
                  //     ? floatValue <= Math.round(data.qty_item ?? 0) || value === ""
                  //     : true
                  // }
                  onChange={(value) => setQtyAnalisa(value)}
                  thousandSeparator={false}
                  error={Boolean(errors.qty_jobmix && touched.qty_jobmix)}
                  errorText={
                    Boolean(errors.qty_jobmix && touched.qty_jobmix) &&
                    errors.qty_jobmix
                  }
                />
              </Col>
              <Col md={6} lg>
                <Input
                  readOnly
                  label="Satuan"
                  placeholder="-"
                  value={values.kode_satuan}
                />
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default InfoFormSection;
