// Component
import { Tr, Td, TdFixed } from "components";
import { Row, Col } from "react-bootstrap";
import { DateConvert } from "utilities";

const InfoRABSection = ({ data }) => {
  const List = ({ label, children }) => (
    <Tr>
      <Td>
        <b>{label}</b>
      </Td>
      <TdFixed>:</TdFixed>
      <Td>{children}</Td>
    </Tr>
  );

  return (
    <>
      <Row>
        {/* <Col md>
					<List label="Tgl. Unit Cost">{data?.tgl_rae ? DateConvert(new Date(data.tgl_rae)).detail : '-'}</List>
					<List label="No. Unit Cost">{data?.no_rae ?? '-'}</List>
				</Col> */}

        <Col md>
          <List label="Tgl. RAB">
            {data?.tgl_rab ? DateConvert(new Date(data.tgl_rab)).detail : "-"}
          </List>
          <List label="No. RAB">{data?.no_rab ?? "-"}</List>
        </Col>
      </Row>

      <hr />
    </>
  );
};

export default InfoRABSection;
