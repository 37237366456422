export const formInitialValues = (data) => ({
  idGudang: data?.id_gudang,
  idStockFisik: data?.id_stock_fisik_master,
  idUnitProduksi: data?.id_unit_produksi,
  isOpname: data?.is_opname,
  namaGudang: data?.nama_gudang,
  namaUnitProduksi: data?.nama_unit_produksi,
  noStockFisik: data?.no_stock_fisik_master,
  tglStockFisik: data?.tgl_stock_fisik_master,
  tglStockOpname: data?.tglStockOpname
    ? data?.tglStockOpname
    : data?.tgl_stock_opname_master,
  noStockOpname: data?.no_stock_opname_master ?? undefined,
  detail: data?.detail
    ? data?.detail.map((val) => ({
        hsr: val?.hsr,
        idItemBarang: val?.id_item_barang,
        idSatuan: val?.id_satuan,
        idStockFisikDetail: val?.id_stock_fisik_detail,
        idStockFisikMaster: val?.id_stock_fisik_master,
        kodeItem: val?.kode_item,
        namaItem: val?.nama_item,
        namaJenis: val?.nama_jenis,
        namaKelompok: val?.nama_kelompok,
        namaSatuan: val?.nama_satuan,
        qtyPersediaan: val?.qty_stock_akhir
          ? val?.qty_stock_akhir
          : val?.qty_persediaan,
        hargaStockAkhir: 0,
        qtyStockFisik: val?.qty_stock_fisik,
        hargaStockFisik: 0,
        qtyAdjustment: val.qty_adjustment
          ? val?.qty_adjustment
          : parseFloat(val.qty_stock_fisik) - parseFloat(val.qty_persediaan),
        hargaAdjustment: 0,
        keterangan: val.keterangan ?? "",
      }))
    : [],
});
