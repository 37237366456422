import Services from "services"

class SetupStokAwal {
  getPage(params) {
    return Services.get("/stok_awal/page", { params })
  }

  create(value) {
    return Services.post("/stok_awal", value)
  }

  getDropdownKelompok() {
    return Services.get('dropdown/kelompok_material')
  }

  getDropdownJenis(value) {
    return Services.get(`/dropdown/jenis_material?id_kelompok=${value}`)
  }

  getDropdownBahan(params) {
    return Services.get("/bahan/dropdown", { params })
  }

  getDropdownGudang(params) {
    return Services.get("/gudang/dropdown", { params })
  }
}

export default new SetupStokAwal()
