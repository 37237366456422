import Services from "../../../services";

class RekapitulasiNilaiPersediaan {
  getReport(params) {
    return Services.get("/rekapitulasi_nilai/page", { params });
  }

  getSummary(params) {
    return Services.get("/rekapitulasi_nilai/summary", { params });
  }

  getDropdown(params) {
    return Services.get("/laporan_stok_update/dropdown", { params });
  }

  export(params) {
    return Services.get("/rekapitulasi_nilai", { params });
  }
}

export default new RekapitulasiNilaiPersediaan();
