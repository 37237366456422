import React, { forwardRef } from "react";
import Logo from "assets/image/LogoAJB.png";
import { InfoSection, TableSection } from "./PrintSection";
import { Col, Row } from "react-bootstrap";

const PrintSuratJalan = forwardRef(
  ({ data, dataInfo, dataTable, formik }, ref) => {
    const KopSurat = () => {
      return (
        <>
          <div className="d-flex justify-content-between m-0">
            <div className="d-flex justify-content-start m-0">
              <div>
                <img src={Logo} width={110} />
              </div>
              <div style={{ marginLeft: 30, textAlign: "left" }}>
                <p>
                  <b>PT. ADI JAYA BETON</b>
                  <br />
                  Jl. ByPass Ngurah Rai
                  <br />
                  Telp. : (0361) 467342, 467343
                  <br />
                  Fax: (0361) 467301
                  <br />
                </p>
              </div>
            </div>
            <div className="text-right font-weight-bold mt-3 pt-4">
              <b style={{ fontSize: 50 }}>Surat Jalan</b>
            </div>
          </div>
        </>
      );
    };

    const TTDSection = ({ title, value, jabatan }) => {
      return (
        <div className="text-center">
          <div>{title}</div>
          <div style={{ height: 80 }}></div>
          <div>{value}</div>
          <div>
            <hr />
          </div>
        </div>
      );
    };

    return (
      <div className="container my-5 px-5" ref={ref}>
        <KopSurat />
        <hr style={{ marginTop: 0, marginBottom: 2 }} />
        <InfoSection dataInfo={data} />
        <br />
        <TableSection formik={formik} data={data ?? {}} type="detail" />
        <div className="mb-4"></div>
        <Row className="mt-4">
          <Col>
            <TTDSection
              title="Operator BP/Forklift"
              // value="Ir. I Ketut Widiarca, ST"
              // jabatan="Direktur"
            />
          </Col>
          <Col>
            <TTDSection
              title="Petugas Prod/QC"
              // value="Ir. I Ketut Widiarca, ST"
              // jabatan="Direktur"
            />
          </Col>
          <Col>
            <TTDSection
              title="Petugas Pengiriman"
              // value="Ir. I Ketut Widiarca, ST"
              // jabatan="Direktur"
            />
          </Col>
          <Col>
            <TTDSection
              title="Keamanan"
              // value="Ir. I Ketut Widiarca, ST"
              // jabatan="Direktur"
            />
          </Col>
          <Col>
            <TTDSection
              title="Penerima/Pembeli"
              // value="Ir. I Ketut Widiarca, ST"
              // jabatan="Direktur"
            />
          </Col>
        </Row>
      </div>
    );
  }
);

export default PrintSuratJalan;
