import Services from "services"

class MappingItemApi {
    page(params) {
        return Services.get("/mapping_item_gudang/page", { params })
    }

    dropdown(params) {
        return Services.get("/mapping_item_gudang/dropdown", { params })
    }

    create(data) {
        return Services.post("/mapping_item_gudang", data)
    }
    
    update(data) {
        return Services.put("/mapping_item_gudang", data)
    }

    delete(data) {
        return Services.post("/mapping_item_gudang/delete", data)
    }
}

export default new MappingItemApi();
