// React
import React, { useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

// Component
import { Card, Row, Col, FormFile } from "react-bootstrap";
import {
  CRUDLayout,
  Input,
  DataStatus,
  Alert,
  SelectSearch,
  ActionButton,
  BackButton,
  NumberFormat,
  AlertAlreadyStockOpname,
} from "../../../../components";
import { DateConvert, RupiahConvert } from "utilities";

// Form
import { Formik } from "formik";
import * as Yup from "yup";

// API
import Axios from "axios";
import { PenerimaanBarangApi } from "../../../../api";

// File Uploader
import ImageViewer from "react-simple-image-viewer";

const TambahPenerimaanBarang = ({ setNavbarTitle }) => {
  const title = "Penerimaan Barang";
  const {
    location: {
      state: { qty_sisa },
    },
  } = useHistory();
  const history = useHistory();
  const { id } = useParams();
  const location = useLocation();

  // STATE LOADING
  const [isLoading, setIsLoading] = useState(false);

  // STATE MENAMPUNG DATA
  const [data, setData] = useState({
    penerimaan_barang: [],
  });
  const [dataAtribut, setDataAtribut] = useState({
    gudang: [],
    karyawan: [],
    satuan: [],
  });
  const [dataNo, setDataNo] = useState("");
  const [dataStore, setDataStore] = useState({
    tgl_penerimaan_barang: undefined,
  });
  const [selectedFileNIK, setSelectedFileNIK] = useState();
  const [nameFileNIK, setNameFileNIK] = useState("");

  // ALERT STATE
  const [showAlert, setShowAlert] = useState(false);

  // KONFIGURASI ALERT
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });

  // FUNCTION GET DATA DARI SERVER
  const getData = () => {
    setIsLoading(true);

    Axios.all([
      PenerimaanBarangApi.getSingle("id_purchase_order_detail", id),
      PenerimaanBarangApi.getAtribut("gudang"),
      PenerimaanBarangApi.getAtribut("karyawan"),
      PenerimaanBarangApi.getAtribut("satuan"),
    ])
      .then(
        Axios.spread((data, gudang, karyawan, satuan) => {
          setData(data.data.data);
          setDataAtribut({
            gudang: gudang.data.data,
            karyawan: karyawan.data.data,
            satuan: satuan.data.data,
          });
        })
      )
      .catch((err) => alert(err.response.data.message))
      .finally(() => setIsLoading(false));
  };

  const getDataNo = (value) => {
    if (isNaN(value)) {
      PenerimaanBarangApi.getNo(value).then((res) => {
        setDataNo(res.data.data);
      });
    }
  };

  const decimalConvert = (value) => {
    const convertToNumber = parseFloat(value);
    const checkConvert =
      convertToNumber === NaN ? 0 : parseFloat(String(convertToNumber));

    return new Intl.NumberFormat("id-ID").format(checkConvert);
  };

  // ON COMPONENT MOUNT
  useEffect(() => {
    // setIsLoading(true)
    setNavbarTitle(title); // SET JUDUL NAVBAR
    getData(); // GET DATA DARI SERVER
    // getDataNo(DateConvert(new Date()).default);

    // eslint-disable-next-line
  }, []);

  const InputFileNIK = () => {
    const [preview, setPreview] = useState();

    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const filesViewer = [preview];

    useEffect(() => {
      if (!selectedFileNIK) {
        setPreview(undefined);
        return;
      }

      const objectUrl = URL.createObjectURL(selectedFileNIK);
      setPreview(objectUrl);

      return () => URL.revokeObjectURL(objectUrl);

      // eslint-disable-next-line
    }, [selectedFileNIK]);

    const onSelectFile = (e) => {
      if (!e.target.files || e.target.files.length === 0) {
        setSelectedFileNIK(undefined);
        setNameFileNIK(undefined);

        return;
      }

      setSelectedFileNIK(e.target.files[0]);
      setNameFileNIK(e.target.files[0].name);
    };

    const closeImageViewer = () => {
      setIsViewerOpen(false);
    };
    return (
      <>
        <small>Upload Foto Bukti Penerimaan Barang</small>
        <FormFile custom style={{ zIndex: 0 }}>
          <FormFile.Input size="sm" onChange={onSelectFile} accept="image/*" />
          <FormFile.Label style={{ fontSize: 14 }}>
            {nameFileNIK ? nameFileNIK : "Pilih Gambar"}
          </FormFile.Label>
        </FormFile>
        {selectedFileNIK && (
          <img
            src={preview}
            onClick={() => {
              setIsViewerOpen(true);
            }}
            alt=""
            className="mt-4 mb-4 img-fluid"
          />
        )}
        {isViewerOpen && (
          <ImageViewer
            src={filesViewer}
            currentIndex={0}
            disableScroll={false}
            onClose={closeImageViewer}
          />
        )}
      </>
    );
  };

  // INITIAL VALUES FORM TAMBAH DATA TIPE BARANG JADI
  const formInitialValues = {
    tgl_penerimaan_barang: dataStore.tgl_penerimaan_barang ?? undefined,
    id_purchase_order_detail: id,
    no_surat_jalan: dataStore.no_surat_jalan ?? "",
    petugas_penerimaan: dataStore.petugas_penerimaan ?? null,
    gudang_penerimaan: dataStore.gudang_penerimaan ?? null,
    qty_beli: dataStore.qty_beli ?? "",
    id_satuan_beli: data?.id_satuan_beli ?? null,
    qty_pakai: dataStore.qty_pakai ?? "",
    id_satuan_pakai: data?.id_satuan_pakai ?? null,
    nilai_konversi: data?.nilai_konversi ?? 0,
  };

  // FORMIK VALIDATION SCHEMA TIPE BARANG JADI DENGAN YUP VALIDATION
  const formValidationSchema = Yup.object().shape({
    tgl_penerimaan_barang: Yup.string().required("Tanggal wajib diisi"),
    no_surat_jalan: Yup.string().required("No. Surat Jalan wajib diisi"),
    petugas_penerimaan: Yup.string().required("Pilih Petugas").nullable(),
    gudang_penerimaan: Yup.string().required("Pilih Gudang").nullable(),
    qty_beli: Yup.string()
      .required("Qty. Beli harus isi")
      .test("check-qty", "Qty. beli tidak boleh melebihi Qty. sisa", (val) =>
        parseFloat(val) > parseFloat(data?.qty_sisa) || parseFloat(val) === 0
          ? false
          : true
      ),
  });

  // KIRIM UPDATE DATA KE SERVER
  const formSubmitHandler = (values, { setSubmitting }) => {
    if (!selectedFileNIK) {
      alert("Foto Bukti Penerimaan Barang wajib diisi");
      setSubmitting(false);
    } else {
      const NIK = [selectedFileNIK];
      const formData = new FormData();

      NIK.reduce((_acc, file, _index) => {
        formData.append("file", file);
        return file;
      }, {});

      PenerimaanBarangApi.upload(formData).then((data) => {
        let valueUpload = {
          ...values,
          foto_surat_jalan: data.data.data,
        };
        PenerimaanBarangApi.create(valueUpload)
          .then(() => {
            history.push("/inventory/transaksi/penerimaan-barang/list", {
              registerAlertConfig: {
                variant: "primary",
                text: "Tambah data berhasil!",
              },
              ...location.state,
            });
          })
          .catch((err) => {
            setAlertConfig({
              variant: "danger",
              text: `Tambah data gagal! (${err.response.data.message})`,
            });
          });
      });
    }
  };

  return (
    <CRUDLayout>
      {/* Alert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Tambah Data Penerimaan Barang</b>
          <BackButton
            size="sm"
            onClick={() =>
              history.push(
                "/inventory/transaksi/penerimaan-barang/list",
                location.state
              )
            }
          />
        </Card.Header>
        {isLoading ? (
          <DataStatus loading={isLoading} text="Memuat Data" />
        ) : (
          <Formik
            enableReinitialize={true}
            initialValues={formInitialValues}
            validationSchema={formValidationSchema}
            onSubmit={formSubmitHandler}
          >
            {({
              values,
              errors,
              touched,
              isSubmitting,
              handleSubmit,
              setFieldValue,
            }) => {
              console.log(data);
              const harga =
                parseFloat(data.harga_kesepakatan) +
                parseFloat(data.harga_kesepakatan_biaya_angkut);

              const hargaKonversi =
                parseFloat(harga) / parseFloat(data.nilai_konversi ?? 0);

              return (
                <form onSubmit={handleSubmit}>
                  <Card.Body>
                    <Row className="mb-3">
                      <Col sm="6">
                        <Row>
                          <Col md="4">
                            <small>Tgl. Purchase Order</small>
                          </Col>
                          <Col md="8">
                            <small>
                              {" "}
                              :{" "}
                              {data?.tgl_purchase_order
                                ? DateConvert(new Date(data.tgl_purchase_order))
                                    .detail
                                : "-"}
                            </small>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="4">
                            <small>No. Purchase Order</small>
                          </Col>
                          <Col md="8">
                            <small> : {data.no_purchase_order}</small>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="4">
                            <small>Vendor</small>
                          </Col>
                          <Col md="8">
                            <small> : {data.nama_vendor}</small>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="4">
                            <small>Item Barang</small>
                          </Col>
                          <Col md="8">
                            <small>: {data.nama_item}</small>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="4">
                            <small>Proyek</small>
                          </Col>
                          <Col md="8">
                            <small>: {data.nama_proyek ?? "-"}</small>
                          </Col>
                        </Row>
                      </Col>

                      <Col sm="6">
                        <Row>
                          <Col md="4">
                            <small>Qty Purchase Order</small>
                          </Col>
                          <Col md="8">
                            <small>
                              {" "}
                              :{" "}
                              {decimalConvert(
                                parseFloat(data.qty_order ?? 0)
                              )}{" "}
                              {data.kode_satuan_beli}
                            </small>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="4">
                            <small>Qty Menunggu Persetujuan</small>
                          </Col>
                          <Col md="8">
                            <small>
                              {" "}
                              :{" "}
                              {decimalConvert(
                                parseFloat(data.qty_menunggu ?? 0)
                              )}{" "}
                              {data.kode_satuan_beli}
                            </small>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="4">
                            <small>Qty Telah Diterima</small>
                          </Col>
                          <Col md="8">
                            <small>
                              :{" "}
                              {decimalConvert(
                                parseFloat(data.qty_diterima ?? 0)
                              )}{" "}
                              {data.kode_satuan_beli}
                            </small>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="4">
                            <small>Qty Sisa</small>
                          </Col>
                          <Col md="8">
                            <small>
                              : {decimalConvert(parseFloat(data.qty_sisa ?? 0))}{" "}
                              {data.kode_satuan_beli}
                            </small>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="4">
                            <small>Keterangan PO</small>
                          </Col>
                          <Col md="8">
                            <small>
                              : {data.keterangan_po ? data.keterangan_po : "-"}
                            </small>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <hr></hr>
                    <Row>
                      {console.log("form", values)}
                      <Col md>
                        <Row>
                          <Col lg>
                            <Input
                              label="Tgl. Penerimaan Barang"
                              type="date"
                              name="tgl_penerimaan_barang"
                              onChange={(val) => {
                                setFieldValue(
                                  "tgl_penerimaan_barang",
                                  val.target.value
                                );
                                getDataNo(val.target.value);
                                setDataStore({
                                  ...dataStore,
                                  tgl_penerimaan_barang: val.target.value,
                                });
                              }}
                              value={values.tgl_penerimaan_barang}
                              error={
                                errors.tgl_penerimaan_barang &&
                                touched.tgl_penerimaan_barang &&
                                true
                              }
                              errorText={errors.tgl_penerimaan_barang}
                            />
                          </Col>

                          <Col lg>
                            <Input
                              label="No. Penerimaan Barang"
                              type="text"
                              readOnly={true}
                              value={dataNo}
                            />
                          </Col>
                        </Row>

                        <Input
                          label="No. Surat Jalan Dari Vendor"
                          type="text"
                          name="no_surat_jalan"
                          onChange={(e) => {
                            setDataStore({
                              ...dataStore,
                              no_surat_jalan: e.target.value,
                            });
                          }}
                          error={
                            errors.no_surat_jalan &&
                            touched.no_surat_jalan &&
                            true
                          }
                          errorText={errors.no_surat_jalan}
                        />

                        <SelectSearch
                          label="Gudang Penerimaan Barang"
                          name="gudang_penerimaan"
                          onChange={(val) => {
                            setFieldValue("gudang_penerimaan", val.value);
                            setDataStore({
                              ...dataStore,
                              gudang_penerimaan: val.value,
                            });
                          }}
                          option={dataAtribut.gudang.map((val) => {
                            return {
                              value: val.id_gudang,
                              label: val.nama_gudang,
                            };
                          })}
                          defaultValue={{
                            value: "",
                            label: "Pilih Gudang Penerimaan",
                          }}
                          error={
                            errors.gudang_penerimaan &&
                            touched.gudang_penerimaan &&
                            true
                          }
                          errorText={errors.gudang_penerimaan}
                        />

                        <SelectSearch
                          label="Petugas Penerimaan"
                          name="petugas_penerimaan"
                          onChange={(val) => {
                            setFieldValue("petugas_penerimaan", val.value);
                            setDataStore({
                              ...dataStore,
                              petugas_penerimaan: val.value,
                            });
                          }}
                          option={dataAtribut.karyawan.map((val) => {
                            return {
                              value: val.id_karyawan,
                              label: val.nama_karyawan,
                            };
                          })}
                          defaultValue={{
                            value: "",
                            label: "Pilih Petugas",
                          }}
                          error={
                            errors.petugas_penerimaan &&
                            touched.petugas_penerimaan &&
                            true
                          }
                          errorText={errors.petugas_penerimaan}
                        />

                        <small>Qty. Penerimaan (Dalam Satuan Beli)</small>
                        <Row>
                          <Col lg>
                            <NumberFormat
                              label=""
                              className="text-right"
                              value={values.qty_beli}
                              defaultValue={values.qty_beli}
                              onChange={(val) => {
                                const value = val > data.qty_order ? 0 : val;
                                const konversi = data?.nilai_konversi
                                  ? parseFloat(data.nilai_konversi)
                                  : 1;
                                const qty_pakai = value * konversi;
                                setDataStore({
                                  ...dataStore,
                                  qty_beli: value,
                                  qty_pakai,
                                });
                                setFieldValue("qty_beli", value);
                                setFieldValue("qty_pakai", qty_pakai);
                              }}
                              error={
                                errors.qty_beli && touched.qty_beli && true
                              }
                              errorText={errors.qty_beli}
                            />
                          </Col>

                          <Col lg>
                            <Input
                              type="text"
                              value={data?.nama_satuan_beli ?? "-"}
                              readOnly={true}
                            />
                          </Col>
                        </Row>

                        <Input
                          label="Harga Barang Per Satuan Beli"
                          type="text"
                          className="text-right"
                          value={
                            data?.harga_kesepakatan
                              ? RupiahConvert(parseFloat(harga).toString())
                                  .getWithComa
                              : "Rp0"
                          }
                          readOnly={true}
                        />

                        <small>Qty. Penerimaan (Dalam Satuan Pakai)</small>
                        <Row>
                          <Col lg>
                            <NumberFormat
                              label=""
                              className="text-right"
                              value={values.qty_pakai}
                              defaultValue={values.qty_pakai}
                              onChange={(value) => {
                                const konversi = data?.nilai_konversi
                                  ? parseInt(data.nilai_konversi)
                                  : 1;
                                const qty_beli = value / konversi;
                                setDataStore({
                                  ...dataStore,
                                  qty_pakai: value,
                                  qty_beli,
                                });
                                setFieldValue("qty_pakai", value);
                                setFieldValue("qty_beli", qty_beli);
                              }}
                            />
                          </Col>

                          <Col lg>
                            <Input
                              type="text"
                              value={data?.nama_satuan_pakai ?? "-"}
                              readOnly={true}
                            />
                          </Col>
                        </Row>

                        <Input
                          label="Harga Barang Per Satuan Pakai"
                          type="text"
                          className="text-right"
                          value={
                            data?.harga_kesepakatan
                              ? RupiahConvert(
                                  parseFloat(hargaKonversi).toString()
                                ).getWithComa
                              : "Rp0"
                          }
                          readOnly={true}
                        />
                      </Col>

                      <Col md>
                        <InputFileNIK />
                      </Col>
                    </Row>
                    <AlertAlreadyStockOpname
                      tanggal={values?.tgl_penerimaan_barang}
                    />
                  </Card.Body>
                  <Card.Footer>
                    <div className="text-right">
                      <ActionButton
                        type="action"
                        className="btn btn-primary text-white"
                        size="md"
                        text="Simpan"
                        loading={isSubmitting}
                      />
                    </div>
                  </Card.Footer>
                </form>
              );
            }}
          </Formik>
        )}
      </Card>
    </CRUDLayout>
  );
};
export default TambahPenerimaanBarang;
