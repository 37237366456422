import React from "react";
import { Modal } from "react-bootstrap";
import InfoSection from "./InfoSection";

const ModalSalesOrder = ({ setModalConfig, modalConfig }) => {
  return (
    <div>
      <Modal
        show={modalConfig}
        onHide={() => setModalConfig({ show: false })}
        size="xl"
        scrollable
      >
        <Modal.Header closeButton>Detail Sales Order</Modal.Header>
        <Modal.Body>
          <InfoSection idSales={modalConfig?.id} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ModalSalesOrder;
