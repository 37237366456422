import Services from "services";

class ConvertItemApi {
  get(params) {
    return Services.get("/convert_item/page", { params });
  }

  getSingle(params) {
    return Services.get("/convert_item/single", { params });
  }

  getNomor(params) {
    return Services.get("/convert_item/no_baru", { params });
  }

  getBahan() {
    return Services.get("/convert_item/bahan");
  }

  dropdownUnitProduksi() {
    return Services.get("/convert_item/unit_produksi");
  }

  dropdownGudang() {
    return Services.get("/convert_item/gudang");
  }

  dropdownGudangWithStock(params) {
    return Services.get("/convert_item/gudang_with_stock", { params });
  }

  create(value) {
    return Services.post("/convert_item", value);
  }

  update(value) {
    return Services.put("/convert_item", value);
  }
}

export default new ConvertItemApi();
