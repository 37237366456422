// React
import React, {useState, useEffect, useRef} from 'react'

// Form
import { Formik } from 'formik'

// Components
import { DatePicker, FilterModal, SelectSearch } from 'components'

// API
import Axios from "axios"
import { PenerimaanBarangApi } from 'api'
import { mappingData, mappingDataItem } from '.'
import { DateConvert } from 'utilities'

const ModalFilter = ({ show, setShow, data, setData, isPenerimaan }) => {
	// Variables
	const option = { label: 'Semua Data', value: undefined }

	// Hooks
	const firstRender = useRef(true)

	// States
	const [dropdownFilter, setDropdownFilter] = useState({
		vendor: [option],
		item_barang: [option],
		status_approval: [option]
	  })
	const [loading, setLoading] = useState(true)

	// REQUSET DATA SERVER
	const getDataDropdown = () => {
		Axios.all([
			PenerimaanBarangApi.vendor(),
			PenerimaanBarangApi.item_bahan()
		])
		.then(Axios.spread((vendor, item_bahan) => {
			setDropdownFilter({
				vendor: [option, ...mappingData(vendor, 'vendor')],
				item_barang: [option, ...mappingDataItem(item_bahan, 'item_buaso')],
				status_approval: [
					option,
					{
						value: "APP",
						label: "APPROVED",
					},
					{
						value: "VER",
						label: "VERIFIED",
					},
					{
						value: "REV",
						label: "REVISI",
					},
					{
						value: "REJ",
						label: "REJECT",
					},
					{
						value: "PEN",
						label: "PENDING",
					}
				],
			})
		}))
		.finally(() => {
			setLoading(false)
			firstRender.current = false
		})
	}

	// USE EFFECT ALL DROPDOWN
    useEffect(() => {
		getDataDropdown()

		// eslint-disable-next-line
    }, [])

	// FORM VALUES
	const formInitialValues = {
		tgl_penerimaan_barang_mulai: data.tgl_penerimaan_barang_mulai,
		tgl_penerimaan_barang_selesai: data.tgl_penerimaan_barang_selesai,
		tgl_purchase_order_mulai: data.tgl_purchase_order_mulai,
		tgl_purchase_order_selesai: data.tgl_purchase_order_selesai,
		vendor: data.vendor,
		item_barang: data.item_barang,
		status_approval: data.status_approval
	}
	const formSubmitHandler = (values) => {
		const checkActive = !!Object.values(values).filter(val => val !== undefined).length > 0

		setData({
			...data,
			...values,
			active: checkActive,
			page: 1
		})

		setShow(false)
	}

	// HANDLE CHANGE
	const onResetButtonClick = setValues => {
		setLoading(true)

		const myPromise = new Promise(resolve => {
			setValues({
				tgl_penerimaan_barang_mulai: undefined,
				tgl_penerimaan_barang_selesai: undefined,
				tgl_purchase_order_mulai: undefined,
				tgl_purchase_order_selesai: undefined,
				vendor: undefined,
				item_barang: undefined,
				status_approval: undefined,
			})

			setTimeout(() => resolve('foo'), 300)
		})

		myPromise.then(() => setLoading(false))
	}

	const onTanggalChange = (dates, values, setValues, value_awal, value_akhir) => {
		const [start, end] = dates
		const startDate = start ? DateConvert(start).default : null
		const endDate = end ? DateConvert(end).default : null
	
		setValues({
		  ...values,
		  [value_awal]: startDate,
		  [value_akhir]: endDate
		})
	  }

	return (
		<Formik
			enableReinitialize
			initialValues={formInitialValues}
			onSubmit={formSubmitHandler}
		>	
			{({values, handleSubmit, setFieldValue, setValues}) => (
				<FilterModal
					show={show}
					setShow={setShow}
					onResetButtonClick={() => onResetButtonClick(setValues)}
					onFilterButtonClick={handleSubmit}
				>
					{isPenerimaan && (
						<DatePicker 
							selectsRange
							label="Tgl. Penerimaan Barang"
							placeholderText="Pilih Tanggal Penerimaan Barang"
							startDate={values.tgl_penerimaan_barang_mulai ? new Date(values.tgl_penerimaan_barang_mulai) : ''}
							endDate={values.tgl_penerimaan_barang_selesai ? new Date(values.tgl_penerimaan_barang_selesai) : ''}
							onChange={dates => onTanggalChange(dates, values, setValues, 'tgl_penerimaan_barang_mulai', 'tgl_penerimaan_barang_selesai')}
							monthsShown={2}
						/>
					)}

					<DatePicker 
						selectsRange
						label="Tgl. Purchase Order"
						placeholderText="Pilih Tanggal Purchase Order"
						startDate={values.tgl_purchase_order_mulai ? new Date(values.tgl_purchase_order_mulai) : ''}
						endDate={values.tgl_purchase_order_selesai ? new Date(values.tgl_purchase_order_selesai) : ''}
						onChange={dates => onTanggalChange(dates, values, setValues, 'tgl_purchase_order_mulai', 'tgl_purchase_order_selesai')}
						monthsShown={2}
					/>

					<SelectSearch 
						key="1"
						label="Vendor"
						placeholder="Pilih Vendor"
						defaultValue={dropdownFilter.vendor.find(val => val.value === values.vendor)}
						option={dropdownFilter.vendor}
						onChange={val => setFieldValue('vendor', val.value)}
						loading={loading}
					/>

					<SelectSearch 
						key="2"
						label="Item Barang"
						placeholder="Pilih Item Barang"
						defaultValue={dropdownFilter.item_barang.find(val => val.value === values.item_barang)}
						option={dropdownFilter.item_barang}
						onChange={val => setFieldValue('item_barang', val.value)}
						loading={loading}
					/>

					{isPenerimaan && (
						<SelectSearch 
							key="3"
							label="Status Approval"
							placeholder="Pilih Status Approval"
							defaultValue={dropdownFilter.status_approval.find(val => val.value === values.status_approval)}
							option={dropdownFilter.status_approval}
							onChange={val => setFieldValue('status_approval', val.value)}
							loading={loading}
						/>
					)}
				</FilterModal>
			)}
		</Formik>
	)
}
export default ModalFilter