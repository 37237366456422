import { useState, useContext } from 'react';
import { Formik } from 'formik';
import { DatePicker, FilterModal, SelectSearch } from 'components';
import { DateConvert } from 'utilities';
import { DropdownContext } from '../context/DropdownContext';

export const ModalFilterPage = ({ toggle, data, setData }) => {
  const [loading, setLoading] = useState(false);
  const { dropdownBahan, dropdownGudang } = useContext(DropdownContext);
  const option = { label: 'Semua Data', value: undefined };
  const optionGudang = [option].concat(dropdownGudang?.data ?? []);
  const optionBahan = [option].concat(dropdownBahan?.data ?? []);

  const formInitialValues = {
    tgl_transfer_work_order_bahan_mulai:
      data.tgl_transfer_work_order_bahan_mulai,
    tgl_transfer_work_order_bahan_selesai:
      data.tgl_transfer_work_order_bahan_selesai,

    tgl_permintaan_work_order_bahan_mulai:
      data.tgl_permintaan_work_order_bahan_mulai,
    tgl_permintaan_work_order_bahan_selesai:
      data.tgl_permintaan_work_order_bahan_selesai,

    id_item_bahan: data.id_item_bahan,
    id_gudang_asal: data.id_gudang_asal,
    id_gudang_tujuan: data.id_gudang_tujuan,
  };
  const formSubmitHandler = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    setData({
      ...data,
      ...values,
      active: checkActive,
      page: 1,
    });

    toggle();
  };

  const onResetButtonClick = (setValues) => {
    setLoading(true);

    const myPromise = new Promise((resolve) => {
      setValues({
        tgl_transfer_work_order_bahan_mulai: undefined,
        tgl_transfer_work_order_bahan_selesai: undefined,
        tgl_permintaan_work_order_bahan_mulai: undefined,
        tgl_permintaan_work_order_bahan_selesai: undefined,
        id_item_bahan: undefined,
        id_gudang_asal: undefined,
        id_gudang_tujuan: undefined,
      });

      setTimeout(() => resolve(), 300);
    });

    myPromise.then(() => setLoading(false));
  };

  const onTanggalChange = (
    dates,
    values,
    setValues,
    value_mulai,
    value_selesai,
  ) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      [value_mulai]: startDate,
      [value_selesai]: endDate,
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={formSubmitHandler}
    >
      {({ values, handleSubmit, setFieldValue, setValues }) => (
        <FilterModal
          isToggle
          toggleModal={toggle}
          onResetButtonClick={() => onResetButtonClick(setValues)}
          onFilterButtonClick={handleSubmit}
        >
          <DatePicker
            selectsRange
            monthsShown={2}
            label="Tgl. Transfer Bahan Work Order"
            placeholderText="Pilih Tanggal Transfer Bahan Work Order"
            startDate={
              values?.tgl_transfer_work_order_bahan_mulai
                ? new Date(values?.tgl_transfer_work_order_bahan_mulai)
                : ''
            }
            endDate={
              values?.tgl_transfer_work_order_bahan_selesai
                ? new Date(values?.tgl_transfer_work_order_bahan_selesai)
                : ''
            }
            onChange={(dates) =>
              onTanggalChange(
                dates,
                values,
                setValues,
                'tgl_transfer_work_order_bahan_mulai',
                'tgl_transfer_work_order_bahan_selesai',
              )
            }
          />

          <DatePicker
            selectsRange
            label="Tgl. Permintaan Work Order"
            placeholderText="Pilih Tanggal Permintaan Produksi"
            startDate={
              values?.tgl_permintaan_work_order_bahan_mulai
                ? new Date(values?.tgl_permintaan_work_order_bahan_mulai)
                : ''
            }
            endDate={
              values?.tgl_permintaan_work_order_bahan_selesai
                ? new Date(values?.tgl_permintaan_work_order_bahan_selesai)
                : ''
            }
            onChange={(dates) =>
              onTanggalChange(
                dates,
                values,
                setValues,
                'tgl_permintaan_work_order_bahan_mulai',
                'tgl_permintaan_work_order_bahan_selesai',
              )
            }
            monthsShown={2}
          />

          <SelectSearch
            key="1"
            label="Item Bahan"
            placeholder="Pilih Item Bahan"
            defaultValue={optionBahan?.find(
              (val) => val.value === values.id_item_bahan,
            )}
            option={optionBahan}
            onChange={(val) => setFieldValue('id_item_bahan', val.value)}
            loading={dropdownBahan?.isLoading || loading}
          />

          <SelectSearch
            key="2"
            label="Gudang Asal"
            placeholder="Pilih Gudang Asal"
            defaultValue={optionGudang.find(
              (val) => val.value === values.id_gudang_asal,
            )}
            option={optionGudang}
            onChange={(val) => setFieldValue('id_gudang_asal', val.value)}
            loading={dropdownGudang.isLoading || loading}
          />

          <SelectSearch
            key="3"
            label="Gudang Tujuan"
            placeholder="Pilih Gudang Tujuan"
            defaultValue={optionGudang?.find(
              (val) => val.value === values.id_gudang_tujuan,
            )}
            option={optionGudang}
            onChange={(val) => setFieldValue('id_gudang_tujuan', val.value)}
            loading={dropdownGudang?.isLoading || loading}
          />
        </FilterModal>
      )}
    </Formik>
  );
};
