import React, {
  memo,
  useState,
  useEffect
} from 'react'
import {
  Col,
  Row,
} from 'react-bootstrap'
import {
  FiShoppingCart,
  FiTruck
} from 'react-icons/fi'
import {
  useQuery
} from 'react-query'
import {
  range,
  orderBy,
  sortBy
} from 'lodash'
import {
  Formik,
} from 'formik'
import {
  Select,
  Button,
  ChartDoughnut,
  ChartBarVertical,
  ChartBarHorizontal,
  ChartLine
} from 'components2'
import {
  dateConvert, 
  numberConvert, 
  rupiahConvert
} from 'utilities2'
import DashboardApi from './__DashboardApi__'

const date = dateConvert()
const rupiah = rupiahConvert()
const number = numberConvert()
const yearNow = date.getYear(new Date())

const monthOptions = [
  {label: 'Januari', value: '01'},
  {label: 'Februari', value: '02'},
  {label: 'Maret', value: '03'},
  {label: 'April', value: '04'},
  {label: 'Mei', value: '05'},
  {label: 'Juni', value: '06'},
  {label: 'Juli', value: '07'},
  {label: 'Agustus', value: '08'},
  {label: 'September', value: '09'},
  {label: 'Oktober', value: '10'},
  {label: 'November', value: '11'},
  {label: 'Desember', value: '12'},
]

const yearOptions = range(2000, parseInt(yearNow + 1)).map(val => ({label: val, value: val}))

const InfoItem = memo(({icon, title, value, loading}) => {
  return (
    <div className="d-flex justify-content-between align-items-center bg-white rounded border shadow-sm px-4" style={{height: '100%'}}>
      <div style={{fontSize: 70}}>
        {icon}
      </div>
      <div className="d-flex flex-column align-items-end justify-content-end">
        <div>{title}</div>
        {
          loading
            ? <small>Memuat data . . .</small>
            : <b style={{fontSize: 40, fontWeight: 1200, lineHeight: 1.2}}>{value}</b>
        }
      </div>
    </div>
  )
})

const InfoDoughnutChart = memo(({color, datasets, title, value}) => (
  <div className="position-relative">
      <div style={{position: 'absolute', top: 38, left: value?.toString()?.length > 2 ? 28 : 32, color: color}}>
        <b style={{fontSize: 14}}>{value}%</b>
      </div>
      <ChartDoughnut
        height={90}
        width={90}
        data={{
          labels: [],
          datasets: datasets
        }}
      />
      <div className="text-center font-weight-bold" style={{color: color}}>{title}</div>
    </div>
), (prev, curr) => {
  return prev.value === curr.value
})

const Dashboard = ({setNavbarTitle}) => {
  const [filter, setFilter] = useState({
    month: date.getMonth(new Date()),
    monthName: date.getDetailMonth(new Date()),
    year: date.getYear(new Date())
  })

  const dateSelected = `${filter.year}-${filter.month}-01`

  const getPR = useQuery(['dashboard', 'pr', dateSelected], () => DashboardApi.getPR({tanggal: dateSelected}))
  const getSJ = useQuery(['dashboard', 'sj', dateSelected], () => DashboardApi.getSJ({tanggal: dateSelected}))
  const getKeperluan = useQuery(['dashboard', 'keperluan', dateSelected], () => DashboardApi.getKeperluan({tanggal: dateSelected}))
  const getNilaiKelompok = useQuery(['dashboard', 'nilai-kelompok', dateSelected], () => DashboardApi.getNilaiKelompok({tanggal: dateSelected}))
  const getNilaiPersediaan = useQuery(['dashboard', 'nilai-persediaan', filter.year], () => DashboardApi.getNilaiPersediaan({tahun_mutasi: filter.year}))
  const getBarangSeringDiminta = useQuery(['dashboard', 'barang-diminta', dateSelected], () => DashboardApi.getBarangSeringDiminta({tanggal: dateSelected}))

  const sortBarangSeringDiminta = getBarangSeringDiminta?.data?.data
    ?.sort((a, b) => {
      const parseA = parseInt(a.qty)
      const parseB = parseInt(b.qty)

      if (parseA < parseB) {
        return 1
      }

      if (parseA > parseB) {
        return -1
      }

      return 0
    }) ?? []

  useEffect(() => {
    setNavbarTitle('Dashboard')
  }, [])
  
  return (
    <>
      {/* Filter Section */}
      <Formik
        initialValues={{
          month: filter.month, 
          monthName: filter.monthName,
          year: filter.year
        }}
        onSubmit={(val) => {
          setFilter({
            month: val.month,
            monthName: val.monthName,
            year: val.year
          })
        }}
      >
        {({values, setFieldValue, setValues, handleSubmit}) => (
          <div className="d-flex justify-content-center justify-content-lg-end">
            <div className="d-flex flex-column flex-md-row">
              
              {/* Bulan */} 
              <div className="d-flex align-items-center mr-2 mb-2">
                <span className="mr-2">Bulan</span>
                <div style={{width: 150}}>
                  <Select 
                    noMargin
                    options={monthOptions}
                    defaultValue={monthOptions.find(val => val.value.toString() === values.month.toString())}
                    onChange={(val) => setValues({...values, month: val.value, monthName: val.label})}
                  />
                </div>
              </div>

              {/* Tahun */}
              <div className="d-flex align-items-center mr-2 mb-2">
                <span className="mr-2">Tahun</span>
                <div style={{width: 150}}>
                  <Select 
                    noMargin
                    options={yearOptions.reverse()}
                    defaultValue={yearOptions.find(val => val.value.toString() === values.year.toString())}
                    onChange={(val) => setFieldValue('year', val.value)}
                  />
                </div>
              </div>

              {/* Button */}
              <div>
                <Button text="Filter" className="px-4" onClick={handleSubmit} />
              </div>
            </div>
          </div>
        )}
      </Formik>

      {/* Info Section */}
      <Row className="mt-3">
        {/* Info Purchase request */}
        <Col md={6} lg={6} xl={3} className="mb-3">
          <InfoItem 
            loading={getPR.isFetching}
            title="Purchase Request"
            icon={<FiShoppingCart />}
            value={getPR.data?.data ?? '-'}
          />
        </Col>

        {/* Info Surat jalan */}
        <Col md={6} lg={6} xl={3} className="mb-3">
          <InfoItem 
            loading={getSJ.isFetching}
            title="Surat Jalan"
            icon={<FiTruck />}
            value={getSJ.data?.data ?? '-'}
          />
        </Col>

        {/* Presentase PR */}
        <Col className="mb-3">
          <div className="d-flex justify-content-between bg-white rounded border shadow-sm px-4 py-2" style={{height: '100%'}}>
            <div className="d-flex align-items-center">
              <b style={{fontSize: 24, lineHeight: 1.4,}}>
                Presentase PR<br />
                Berdasarkan Keperluan
              </b>
            </div>
            {getKeperluan.isFetching
              ? <div className="d-flex justify-content-end align-items-center">
                  Memuat data . . .
                </div>
              : <>
                  <div className="d-flex">
                    <InfoDoughnutChart 
                      title="Stok"
                      color="#0073b9"
                      datasets={[
                        {
                          data: [parseInt(getKeperluan?.data?.data?.produksi ?? 0), 100 - parseInt(getKeperluan?.data?.data?.produksi ?? 0)],
                          backgroundColor: ['#0073b9', '#00abda']
                        },
                      ]}
                      value={parseInt(getKeperluan?.data?.data?.produksi ?? '-')}
                    />
                    <InfoDoughnutChart 
                      title="Produksi"
                      color="#ed6914"
                      datasets={[
                        {
                          data: [parseInt(getKeperluan?.data?.data?.stok ?? 0), 100 - parseInt(getKeperluan?.data?.data?.stok ?? 0)],
                          backgroundColor: ['#ed6914', '#f59e0b'],
                        }
                      ]}
                      value={parseInt(getKeperluan?.data?.data?.stok ?? 0)}
                    />
                  </div>
                </>
            }
          </div>
        </Col>
      </Row>

      {/* Chart Section */}
      <Row>
        <Col md={6} className="my-3">
          <div className="p-3 bg-white border rounded shadow-sm" style={{height: '100%'}}>
            <div className="text-center">
              <b>Nilai Persediaan Barang Bulan {filter.monthName} {filter.year} <br /> Berdasarkan Kelompok Barang</b>
            </div>
              {getNilaiKelompok.isFetching
                ? <div className="d-flex align-items-center justify-content-center my-5">
                    Memuat data . . .
                  </div>
                : <>
                    <ChartBarVertical 
                      showLegend={false}
                      data={{
                        labels: getNilaiKelompok?.data?.data?.map(val => val?.nama_kelompok) ?? [],
                        datasets: [
                          {
                            data: getNilaiKelompok?.data?.data?.map(val => val?.summary) ?? [],
                          },
                        ]
                      }}
                      options={{
                        plugins: {
                          tooltip: {
                            callbacks: {
                              label: (val) => rupiah.getWithComa(val.raw)
                            }
                          }
                        },
                        scales: {
                          y: {
                            ticks: {
                              callback: val => rupiah.getWithComa(val)
                            }
                          }
                        }
                      }}
                    />
                  </>
              }
          </div>
        </Col>
        <Col md={6} className="my-3">
          <div className="p-3 bg-white border rounded shadow-sm" style={{height: '100%'}}>
            <div className="text-center">
              <b>Nilai Persediaan Barang Per Bulan Tahun {filter.year}</b>
            </div>
            {getNilaiPersediaan.isFetching
              ? <div className="d-flex align-items-center justify-content-center my-5">
                  Memuat data . . .
                </div>
              : <>
                  <ChartLine 
                      showLegend={false}
                      data={{
                        labels: monthOptions.map(val => val.label),
                        datasets: [
                          {
                            data: monthOptions.map(val => {
                              const findMonth = getNilaiPersediaan?.data?.data?.find(el => el?.bulan_mutasi?.toString() === val.value.toString())

                              return findMonth?.total ? parseFloat(findMonth.total) : 0
                            }),
                          },
                        ]
                      }}
                      options={{
                        plugins: {
                          tooltip: {
                            callbacks: {
                              label: (val) => rupiah.getWithComa(val.raw)
                            }
                          }
                        },
                        scales: {
                          y: {
                            ticks: {
                              callback: val => rupiah.getWithComa(val)
                            }
                          }
                        }
                      }}
                    />
                </>
              }
          </div>
        </Col>
        <Col md={6} className="my-3">
          <div className="p-3 bg-white border rounded shadow-sm">
            <div className="text-center">
              <b>10 Barang Paling Sering Diminta <br /> Bulan {filter.monthName} {filter.year}</b>
            </div>
            {getBarangSeringDiminta.isFetching
                ? <div className="d-flex align-items-center justify-content-center my-5">
                    Memuat data . . .
                  </div>
                : <>
                    <ChartBarHorizontal 
                      showLegend={false}
                      height={200}
                      data={{
                        labels: sortBarangSeringDiminta?.map(val => val?.nama_item) ?? [],
                        datasets: [
                          {
                            data: sortBarangSeringDiminta?.map(val => val?.qty) ?? [],
                          },
                        ]
                      }}
                      options={{
                        plugins: {
                          tooltip: {
                            callbacks: {
                              label: (val) => number.getWithComa(val.raw, {minimumFractionDigits: 0})
                            }
                          }
                        },
                        scales: {
                          x: {
                            ticks: {
                              callback: val => number.getWithComa(val, {minimumFractionDigits: 0})
                            }
                          }
                        }
                      }}
                    />
                  </>
            } 
          </div>
        </Col>
      </Row>
    </>
  )
}

export default Dashboard