import { Row, Col } from "react-bootstrap";
import { DateConvert } from "utilities";

const InfoSection = ({ dataInfo }) => {
  const InfoItem = ({ title, value }) => (
    <tr>
      <td width={200}>{title}</td>
      <td className="pr-2">:</td>
      <td>{value}</td>
    </tr>
  );

  return (
    <>
      <div className="d-flex justify-content-between">
        <div>
          <InfoItem title="Customer" value={dataInfo.nama_customer} />
          <InfoItem title="Sales" value={dataInfo.nama_sales} />
          <InfoItem title="Proyek" value={dataInfo.nama_proyek} />
          <InfoItem title="Unit Produksi" value={dataInfo.nama_unit_produksi} />
          <InfoItem title="Rencana Pengiriman" />
        </div>
        <div>
          <InfoItem
            title="Tgl. Surat Jalan"
            value={
              dataInfo.tgl_surat_jalan
                ? DateConvert(new Date(dataInfo.tgl_surat_jalan)).detail
                : "-"
            }
          />
          <InfoItem title="No. Surat Jalan " value={dataInfo.no_surat_jalan} />
          <InfoItem
            title="Tgl. Sales Order"
            value={
              dataInfo.tgl_sales_order
                ? DateConvert(new Date(dataInfo.tgl_sales_order)).detail
                : "-"
            }
          />
          <InfoItem
            title="No. Sales Order"
            value={dataInfo.no_sales_order ? dataInfo.no_sales_order : "-"}
          />
        </div>
      </div>
    </>
  );
};

export default InfoSection;
