import { useFormik } from "formik"
import {
  DatePicker,
  SelectSearch,
  FilterModal
} from "components"
import { DateConvert } from "utilities"

const ModalFilterPenerimaanBarang = ({ dropdown, modalConfig, setModalConfig, data, setData }) => {
  const filter = data?.filter

  const formInitialValues = {
    tgl_penerimaan_barang_mulai: filter.tgl_penerimaan_barang_mulai,
    tgl_penerimaan_barang_selesai: filter.tgl_penerimaan_barang_selesai,
    tgl_purchase_order_mulai: filter.tgl_purchase_order_mulai,
    tgl_purchase_order_selesai: filter.tgl_purchase_order_selesai,
    vendor: filter.vendor,
    item_barang: filter.item_barang,
    id_satuan_beli: filter.id_satuan_beli,
    karyawan_pengaju: filter.karyawan_pengaju,
    karyawan_penerima: filter.karyawan_penerima,
    id_satuan: filter.id_satuan
  }

  const formSubmitHandler = (values) => {
    const checkActive = !!Object.values(values).filter(val => val !== undefined).length > 0

    if (checkActive) {
      setData(prev => ({
        ...prev,
        filter: { ...values, active: true }
      }))
    } else {
      setData(prev => ({ ...prev, filter: { ...values, active: false } }))
    }
    setModalConfig(false)
  }

  const formik = useFormik({
    initialValues: formInitialValues,
    onSubmit: formSubmitHandler
  })

  const { values, setValues, setFieldValue, handleSubmit } = formik

  const onTanggalPenerimaanChange = (dates) => {
    const [start, end] = dates
    const startDate = start ? DateConvert(start).default : null
    const endDate = end ? DateConvert(end).default : null

    setValues({
      ...values,
      tgl_penerimaan_barang_mulai: startDate,
      tgl_penerimaan_barang_selesai: endDate,
    })
  }

  const onTanggalPurchaseOrderChange = (dates) => {
    const [start, end] = dates
    const startDate = start ? DateConvert(start).default : null
    const endDate = end ? DateConvert(end).default : null

    setValues({
      ...values,
      tgl_purchase_order_mulai: startDate,
      tgl_purchase_order_selesai: endDate,
    })
  }

  const onResetButtonClick = () => {
    setData(prev => ({
      ...prev,
      filter: {
        tgl_penerimaan_barang_mulai: undefined,
        tgl_penerimaan_barang_selesai: undefined,
        tgl_purchase_order_mulai: undefined,
        tgl_purchase_order_selesai: undefined,
        vendor: undefined,
        item_barang: undefined,
        id_satuan_beli: undefined,
        karyawan_pengaju: undefined,
        karyawan_penerima: undefined,
        id_satuan: undefined
      }
    }))

    setModalConfig(false)
  }

  return (
    <FilterModal
      show={modalConfig?.show}
      setShow={setModalConfig}
      onResetButtonClick={() => onResetButtonClick()}
      onFilterButtonClick={handleSubmit}
    >
      <DatePicker
        selectsRange
        monthsShown={2}
        label="Tgl. Penerimaan Barang"
        placeholderText="Pilih tanggal penerimaan barang"
        dateFormat="dd/MM/yyyy"
        startDate={values?.tgl_penerimaan_barang_mulai ? new Date(values.tgl_penerimaan_barang_mulai) : null}
        endDate={values?.tgl_penerimaan_barang_selesai ? new Date(values.tgl_penerimaan_barang_selesai) : null}
        onChange={(selectedDate) => onTanggalPenerimaanChange(selectedDate)}
      />

      <DatePicker
        selectsRange
        monthsShown={2}
        label="Tgl. Purchase Order"
        placeholderText="Pilih tanggal purchase order"
        dateFormat="dd/MM/yyyy"
        startDate={values?.tgl_purchase_order_mulai ? new Date(values.tgl_purchase_order_mulai) : null}
        endDate={values?.tgl_purchase_order_selesai ? new Date(values.tgl_purchase_order_selesai) : null}
        onChange={(selectedDate) => onTanggalPurchaseOrderChange(selectedDate)}
      />

      <SelectSearch
        label="Pembuat"
        placeholder="Pilih Pembuat"
        defaultValue={dropdown?.karyawan?.find((val) => val.value === values.karyawan_pengaju)}
        option={dropdown.karyawan ?? []}
        onChange={(val) => setFieldValue("karyawan_pengaju", val.value)}
      />

      <SelectSearch
        label="Vendor"
        placeholder="Pilih vendor"
        defaultValue={dropdown?.vendor?.find((val) => val.value === values.vendor)}
        option={dropdown.vendor ?? []}
        onChange={(val) => setFieldValue("vendor", val.value)}
      />

      <SelectSearch
        label="Item Barang"
        placeholder="Pilih item barang"
        defaultValue={dropdown?.item_barang?.find((val) => val.value === values.item_barang)}
        option={dropdown.item_barang ?? []}
        onChange={(val) => setFieldValue("item_barang", val.value)}
      />

      <SelectSearch
        label="Satuan"
        placeholder="Pilih satuan"
        defaultValue={dropdown?.satuan?.find((val) => val.value === values.id_satuan_beli)}
        option={dropdown.satuan ?? []}
        onChange={(val) => setFieldValue("id_satuan_beli", val.value)}
      />

      <SelectSearch
        label="Penerima"
        placeholder="Pilih Penerima"
        defaultValue={dropdown?.karyawan?.find((val) => val.value === values.karyawan_penerima)}
        option={dropdown.karyawan ?? []}
        onChange={(val) => setFieldValue("karyawan_penerima", val.value)}
      />
    </FilterModal>
  )
}

export default ModalFilterPenerimaanBarang
