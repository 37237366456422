import { useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import {
  ActionButton,
  BackButton,
  DatePicker,
  InfoItemHorizontal,
  Input,
  SelectSearch,
} from "components";
import { DateConvert } from "utilities";
import { StockOpnameApi } from "api";
import { useFormikContext } from "formik";
import { dateConvert } from "@bhawanadevteam/react-core";

export const FormSection = ({ getInitialValues, isDetail }) => {
  const [isNomorLoading, setIsNomorLoading] = useState(false);
  const { values, errors, touched, setValues } = useFormikContext();

  console.log(values);

  const dateSelected = {
    bulan:
      values?.tglStockOpname &&
      dateConvert().getMonth(new Date(values?.tglStockOpname)),
    tahun:
      values?.tglStockOpname &&
      dateConvert().getYear(new Date(values?.tglStockOpname)),
  };

  const getNomorHandler = (date) => {
    setIsNomorLoading(true);

    StockOpnameApi.getNomor({ tanggal: date })
      .then((no) => {
        const noStokcOpname = no.data.data;

        setValues({
          ...values,
          noStockOpname: noStokcOpname,
          tglStockOpname: date,
        });
      })
      .catch(() => {
        setValues({
          ...values,
          noStock: "",
          tanggal: "",
        });
        window.alert("Nomor gagal dimuat");
      })
      .finally(() => {
        setIsNomorLoading(false);
      });
  };

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <>
      <Row>
        <Col lg="3">
          <DatePicker
            label="Tgl. Stock Opname"
            selected={
              values.tglStockOpname ? new Date(values.tglStockOpname) : ""
            }
            onChange={(date) => {
              const newDate = DateConvert(date).default;
              getNomorHandler(newDate);
            }}
            disabled={isDetail}
            error={Boolean(errors.tglStockOpname) && touched.tglStockOpname}
            errorText={
              Boolean(errors.tglStockOpname) &&
              touched.tglStockOpname &&
              errors.tglStockOpname
            }
          />
        </Col>
        <Col lg="3">
          <Input
            readOnly
            label="No. Stock Opname"
            placeholder={
              isNomorLoading
                ? "Memuat nomor . . ."
                : "Pilih tanggal untuk menentukan nomor"
            }
            value={
              isNomorLoading ? "Memuat nomor . . ." : values?.noStockOpname
            }
          />
        </Col>
        <Col lg="2">
          <Input
            label="Bulan"
            readOnly
            placeholder={
              isNomorLoading
                ? "Memuat bulan . . ."
                : "Pilih tanggal terlebih dahulu"
            }
            value={
              values?.tglStockOpname &&
              dateConvert().getDetailMonth(new Date(values?.tglStockOpname))
            }
          />
        </Col>
        <Col lg="2">
          <Input
            label="Tahun"
            readOnly
            placeholder={
              isNomorLoading
                ? "Memuat tahun . . ."
                : "Pilih tanggal terlebih dahulu"
            }
            value={
              values?.tglStockOpname &&
              dateConvert().getYear(new Date(values?.tglStockOpname))
            }
          />
        </Col>

        <Col lg="2">
          {!isDetail && (
            <div style={{ marginTop: 23 }}>
              <ActionButton
                text="LIHAT LIST STOCK"
                onClick={() =>
                  getInitialValues(
                    dateSelected.bulan,
                    dateSelected.tahun,
                    true,
                    values.tglStockOpname
                  )
                }
              />
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};
