import Services from "services";

class TransferBahanProduksiAsetApi {
  async getList(params) {
    const fetch = await Services.get("/transfer_bahan_produksi_aset/page", {
      params,
    });
    return fetch.data;
  }

  async getSingle(params) {
    const fetch = await Services.get("/transfer_bahan_produksi_aset/single", {
      params,
    });
    return fetch.data;
  }

  async getRealisasi(params) {
    const fetch = await Services.get(
      "/transfer_bahan_produksi_aset/list_realisasi",
      {
        params,
      }
    );
    return fetch.data;
  }

  async dropdownGudang(params) {
    const fetch = await Services.get("/gudang/dropdown", {
      params,
    });

    return fetch?.data?.data.map((val) => ({
      label: val.nama_gudang,
      value: val.id_gudang,
    }));
  }

  async dropdownItem(params) {
    const fetch = await Services.get("/dropdown/item_bahan_produksi_aset", {
      params,
    });

    return fetch?.data?.data.map((val) => ({
      label: val.nama_item,
      value: val.id_item,
    }));
  }
}

export default new TransferBahanProduksiAsetApi();
