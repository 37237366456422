export const formInitialValues = (data, dataDetail) => ({
  tglStock: data?.tgl_stock_fisik_master ?? "",
  noStock: data?.no_stock_fisik_master ?? "",
  idGudang: data?.id_gudang ?? "",
  namaGudang: data?.nama_gudang ?? "",
  idUnitProduksi: data?.id_unit_produksi ?? "",
  namaUnitProduksi: data?.nama_unit_produksi ?? "",
  idStockMaster: data?.id_stock_fisik_master,
  isOpanem: data?.is_opname ?? "",
  detail: data?.detail
    ? data?.detail.map((val) => ({
        idStockDetail: val?.id_stock_fisik_detail,
        idStockMaster: val?.id_stock_fisik_master,
        idStock: val?.id_item_barang ?? val?.id_item_buaso,
        namaItem: val?.nama_item,
        kodeItem: val?.kode_item,
        namaKelompok: val?.nama_kelompok,
        idKelompok: val?.id_kelompok,
        namaJenis: val?.nama_jenis,
        idJenis: val?.id_jenis,
        namaSatuan: val?.nama_satuan ?? val.satuan_pakai,
        qtyStock: parseFloat(val?.qty_stock_fisik ?? 0),
        idItem: val?.id_item_barang,
      }))
    : dataDetail?.map((val) => ({
        idStockDetail: val?.id_stock_fisik_detail,
        idStockMaster: val?.id_stock_fisik_master,
        idStock: val?.id_item_buaso,
        namaItem: val?.nama_item,
        kodeItem: val?.kode_item,
        namaKelompok: val?.nama_kelompok,
        idKelompok: val?.id_kelompok,
        namaJenis: val?.nama_jenis,
        idJenis: val?.id_jenis,
        namaSatuan: val?.satuan_pakai,
        qtyStock: parseFloat(val?.qty_stock_fisik ?? 0),
      })),
});
