import React, { useState, useEffect } from "react";
import Axios from "axios";
import {
  CRUDLayout,
  InputSearch,
  Alert,
  Pagination,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  ActionButton,
  BackButton,
  DataStatus,
  FilterButton,
} from "../../../../components";
import { DeliveryOrderApi } from "../../../../api";
import { useHistory, useLocation } from "react-router-dom";
import { DateConvert, PageNumber, TableNumber } from "../../../../utilities";
import { IoAddOutline } from "react-icons/io5";
import { ModalFilter } from "./components";

const ListSo = ({ setNavbarTitle }) => {
  // Hooks
  const history = useHistory();
  const location = useLocation();
  let createAlert = location?.state?.variant;

  // Variables
  const filter = location?.state?.filter ?? {};

  // States
  const [isLoading, setIsLoading] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [data, setData] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [totalData, setTotalData] = useState("");
  const [modalFilter, setModalFilter] = useState(false);
  const [dataFilter, setDataFilter] = useState({
    active: filter.so?.active,
    page: filter.so?.page ?? 1,
    per_page: filter.so?.per_page ?? 10,
    q: filter.so?.q ?? "",
    tgl_sales_order_mulai: filter.so?.tgl_sales_order_mulai,
    tgl_sales_order_selesai: filter.so?.tgl_sales_order_selesai,
    customer: filter.so?.customer,
    sales: filter.so?.sales,
    gudang: filter.so?.gudang,
  });
  const [showAlert, setShowAlert] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });
  const [searchTime, setSearchTime] = useState(null);

  const getData = () => {
    setIsLoading(true);
    setIsSearching(false);

    const filterData = { ...dataFilter };
    delete filterData.active;

    DeliveryOrderApi.sales_order_page(filterData)
      .then((res) => {
        setData(res.data.data);
        setTotalPage(res.data.total_page);
        setTotalData(res.data.total_data);
      })
      .catch(() => {
        setAlertConfig({
          variant: "danger",
          text: "Data gagal dimuat",
        });
        setShowAlert(true);
      })
      .finally(() => {
        if (dataFilter.q !== "") {
          setAlertConfig({
            variant: "primary",
            text: `Hasil Pencarian : ${dataFilter.q}`,
          });
          setShowAlert(true);
        } else {
          // setShowAlert(false);
        }
        setIsLoading(false);
      });
  };

  const onInputSearch = (text) => {
    const key = text.target.value;
    if (searchTime) clearTimeout(searchTime);
    setSearchTime(
      setTimeout(() => {
        setDataFilter({
          ...dataFilter,
          page: "1",
          dataLength: "10",
          totalPage: "1",
          dataCount: "0",
          q: key,
        });
        setAlertConfig({
          variant: "primary",
          text: `Hasil Pencarian : ${key}`,
        });
        setShowAlert(true);
      }, 750)
    );
  };

  useEffect(() => {
    setNavbarTitle("List Sales Order Yang Siap Dijadikan Delivery Order");

    if (createAlert !== undefined) {
      setShowAlert(true);
      setAlertConfig({
        variant: location?.state?.variant,
        text: location?.state?.text,
      });

      getData();
      history.replace((createAlert = undefined));
    } else {
      return getData();
    }

    return () => {
      setIsLoading(false);
      setIsSearching(false);
      setShowAlert(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNavbarTitle, dataFilter]);

  const Table = () => {
    return (
      <>
        <b>List SO yang Siap Dibuatkan Delivery Order</b>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed>Informasi Sales Order</ThFixed>
              <Th style={{ minWidth: "150px" }}>Customer</Th>
              <Th style={{ minWidth: "150px" }}>Sales</Th>
              {/* <Th style={{ minWidth: "150px" }}>Gudang</Th> */}
              <Th style={{ minWidth: "250px" }}>Item Barang</Th>
              <ThFixed>Batas Waktu</ThFixed>
              <ThFixed>Aksi</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => (
              <Tr key={index}>
                <TdFixed>
                  {PageNumber(dataFilter.page, dataFilter.per_page, index)}
                </TdFixed>
                <Td>
                  <div className="align-middle">
                    {DateConvert(new Date(val.tgl_sales_order)).defaultDMY}
                  </div>
                  <div className="align-middle">{val.no_sales_order}</div>
                </Td>
                <Td className="align-middle">{val.nama_customer}</Td>
                <Td className="align-middle">{val.nama_sales}</Td>
                {/* <Td className="align-middle">
                  {val.nama_gudang ? val.nama_gudang : "-"}
                </Td> */}
                <Td>
                  {val.item_barang ? (
                    <ul className="pl-3">
                      {val.item_barang.map((item, index) => (
                        <li key={index}>{item.nama_barang}</li>
                      ))}
                    </ul>
                  ) : (
                    "-"
                  )}
                </Td>
                <TdFixed className="align-middle">
                  {DateConvert(new Date(val.batas_waktu)).defaultDMY}
                </TdFixed>
                <TdFixed>
                  <div className="d-flex justify-content-center">
                    <ActionButton
                      text={<IoAddOutline />}
                      size="sm"
                      variant="success"
                      onClick={() => {
                        history.push(
                          `/inventory/transaksi/delivery-order/tambah-delivery-order/${val.id_sales_order}`,
                          {
                            data: {
                              nama_gudang: val.nama_gudang,
                            },
                            filter: {
                              ...filter,
                              so: {
                                ...dataFilter,
                                page:
                                  data.length <= 1 &&
                                  parseInt(dataFilter.page) !== 1
                                    ? parseInt(dataFilter.page) - 1
                                    : dataFilter.page,
                              },
                            },
                          }
                        );
                      }}
                    />
                  </div>
                </TdFixed>
              </Tr>
            ))}
          </TBody>
        </CRUDLayout.Table>
        <Pagination
          dataLength={dataFilter.per_page}
          dataPage={
            totalData <= 10
              ? data.length
              : data.map((_res, index) => {
                  if (index === data.length - 1) {
                    return PageNumber(
                      dataFilter.page,
                      dataFilter.per_page,
                      index
                    );
                  } else {
                    return null;
                  }
                })
          }
          dataNumber={data.map((_res, index) => {
            if (index === 0) {
              return PageNumber(dataFilter.page, dataFilter.per_page, index);
            } else {
              return null;
            }
          })}
          dataCount={totalData}
          onDataLengthChange={(e) => {
            setDataFilter({
              ...dataFilter,
              per_page: e.target.value,
              page: 1,
            });
          }}
          currentPage={dataFilter.page}
          totalPage={totalPage}
          onPaginationChange={({ selected }) =>
            setDataFilter({
              ...dataFilter,
              page: selected + 1,
            })
          }
        />
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={onInputSearch}
              onSubmit={(e) => e.preventDefault()}
            />

            <FilterButton
              active={dataFilter?.active}
              onClick={() => setModalFilter(true)}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton
            onClick={() => {
              history.push("/inventory/transaksi/delivery-order", {
                filter: {
                  ...filter,
                  so: dataFilter,
                },
              });
            }}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />
      {isLoading === true ? (
        <DataStatus loading={true} text="Memuat data..." />
      ) : data.length > 0 ? (
        <Table />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      {modalFilter && (
        <ModalFilter
          show={modalFilter}
          setShow={setModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}
    </CRUDLayout>
  );
};

export default ListSo;
