import { useState, useEffect } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import {
  DataStatus,
  InfoItemHorizontal,
  InfoItemVertical,
  Table,
  THead,
  TBody,
  Tr,
  Td,
  Th,
} from "components";
import { DateConvert, DecimalConvert, RupiahConvert } from "utilities";
import { PenerimaanBarangApi } from "api";
import ttd_direktur from "../../../../../assets/image/ttd_direktur.png";
import ttd_manager_sdm from "../../../../../assets/image/ttd_manager_sdm.jpg";
import ttd_petugas from "../../../../../assets/image/ttd_petugas_proc.jpg";

const ModalBodyDetail = ({ data }) => {
  const getPercent = (total, persen) => {
    const checkPersen = parseFloat(persen ?? 0);

    return (checkPersen / 100) * total;
  };

  const getTotal = (harga, qty) => {
    const checkHarga = parseFloat(harga ?? 0);
    const checkQty = parseFloat(qty ?? 0);

    return checkHarga * checkQty;
  };

  const getGrandTotal = (data = []) =>
    data.reduce(
      (prev, curr) => prev + getTotal(curr?.harga_kesepakatan, curr?.qty_order),
      0
    );

  const getGrandTotalAfterDiscount = (data = [], discount) => {
    const total = getGrandTotal(data);
    const totalDiscount = getPercent(total, discount);

    return total - totalDiscount;
  };

  const getGrandTotalAfterPPN = (data = [], discount, ppn) => {
    const total = getGrandTotalAfterDiscount(data, discount);
    const totalPPN = getPercent(total, ppn);

    return total + totalPPN;
  };

  const decimalConvert = (value) => {
    const convertToNumber = parseFloat(value);
    const checkConvert =
      convertToNumber === NaN ? 0 : parseFloat(String(convertToNumber));

    return new Intl.NumberFormat("id-ID").format(checkConvert);
  };

  let total = 0;
  let nilaiDiskon = 0;
  let setelahDiskon = 0;
  let nilaiPPN = 0;
  let setelahPPN = 0;
  let nilaiDP = 0;
  let totalBiayaAngkut = 0;
  let grandTotal = 0;

  data?.po_detail?.map((val, index) => {
    const jumlah = parseFloat(val.qty_order * val.harga_kesepakatan);
    const jumlahBiayaAngkut =
      parseFloat(val.qty_order ?? 0) *
      parseFloat(val.harga_kesepakatan_biaya_angkut ?? 0);

    totalBiayaAngkut = totalBiayaAngkut + jumlahBiayaAngkut;
    total = total + jumlah;
    nilaiDiskon = data?.diskon
      ? parseFloat(data?.diskon) === 0
        ? 0
        : parseFloat((data?.diskon / 100) * total)
      : 0;
    setelahDiskon = parseFloat(total - nilaiDiskon);
    nilaiPPN = data?.ppn
      ? parseFloat(data?.ppn) === 0
        ? 0
        : parseFloat((data?.ppn / 100) * setelahDiskon)
      : 0;
    setelahPPN = parseFloat(setelahDiskon + nilaiPPN);

    grandTotal = setelahPPN + totalBiayaAngkut;
    nilaiDP = data?.dp
      ? parseFloat(data?.dp) === 0
        ? 0
        : parseFloat((data?.dp / 100) * grandTotal)
      : 0;
  });

  const InfoItems = ({ title, value }) => (
    <tr>
      <td width={200}>{title}</td>
      <td className="pr-2">:</td>
      <td>{value}</td>
    </tr>
  );

  const TTDSection = () => {
    const TTDItems = ({ title, value, jabatan, src }) => (
      <Col className="text-center">
        <div>{title}</div>
        {/* <div style={{ height: 80 }}></div> */}
        <div>
          <img src={src} height={80} width={130} />
        </div>
        <div>
          <u>{value}</u>
        </div>
        <div>{jabatan}</div>
      </Col>
    );

    return (
      <Row className="mt-5 pt-3 mb-4">
        <TTDItems
          title="Disahkan,"
          value="I Dewa Gede Tagel"
          src={ttd_direktur}
          jabatan="Direktur"
        />
        <TTDItems
          title="Disetujui,"
          value="I Ketut Sadiana"
          src={ttd_manager_sdm}
          jabatan="Manager SDM, Umum, Procurement"
        />
        <TTDItems
          title="Dibuat Oleh"
          value="I Made Arya Dwi Saputra"
          src={ttd_petugas}
          jabatan="Petugas Procurement"
        />
      </Row>
    );
  };

  console.log(data?.po_detail);

  return (
    <>
      <Row>
        <Col md>
          <InfoItemHorizontal
            label="No. PO"
            text={data?.no_purchase_order ?? "-"}
          />
          <InfoItemHorizontal
            label="Tgl. PO"
            text={
              data?.tgl_purchase_order
                ? DateConvert(new Date(data.tgl_purchase_order)).detail
                : "-"
            }
          />
          <InfoItemHorizontal
            label="Referensi PR"
            text={data?.no_purchase_request ?? "-"}
          />
        </Col>
        <Col md>
          <InfoItemVertical
            label="Kepada"
            text={
              <div className="d-flex flex-column">
                <span>{data?.nama_vendor ?? ""}</span>
                <span>{data?.alamat_vendor ?? ""}</span>
                <span>{data?.telepon ?? ""}</span>
              </div>
            }
          />
        </Col>
      </Row>
      <hr />
      <div className="pb-2">
        <b>Kami tempatkan order pembelian sbb:</b>
      </div>
      <Table>
        <THead>
          <Tr>
            <Th>No</Th>
            <Th>Kode</Th>
            <Th>Item Barang</Th>
            <Th>Proyek</Th>
            <Th>Satuan Beli</Th>
            <Th>Qty. Order</Th>
            <Th>Harga Kesepakatan</Th>
            <Th>Vendor Angkut</Th>
            <Th>Harga Kesepakatan Biaya Angkut</Th>
            <Th>Jumlah</Th>
          </Tr>
        </THead>
        <TBody>
          {data?.po_detail && data?.po_detail?.length > 0 ? (
            data?.po_detail?.map((val, i) => (
              <Tr>
                <Td className="text-center">{i + 1}</Td>
                <Td>{val?.kode_item ?? "-"}</Td>
                <Td>{val?.nama_item ?? "-"}</Td>
                <Td>{val?.nama_proyek ?? "-"}</Td>
                <Td>{val?.nama_satuan_beli ?? "-"}</Td>
                <Td className="text-right">
                  {val?.qty_order
                    ? decimalConvert(parseFloat(val.qty_order))
                    : "-"}
                </Td>

                <Td className="text-right">
                  {val?.harga_kesepakatan
                    ? RupiahConvert(String(parseFloat(val.harga_kesepakatan)))
                        .getWithComa
                    : "-"}
                </Td>
                <Td>{val?.nama_vendor_angkut ?? "-"}</Td>
                <Td className="text-right">
                  {val?.harga_kesepakatan_biaya_angkut
                    ? RupiahConvert(
                        String(parseFloat(val.harga_kesepakatan_biaya_angkut))
                      ).getWithComa
                    : "-"}
                </Td>
                <Td className="text-right">
                  {
                    RupiahConvert(
                      String(getTotal(val.harga_kesepakatan, val.qty_order))
                    ).getWithComa
                  }
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={7}>
                <DataStatus text="Tidak ada data" />
              </Td>
            </Tr>
          )}
        </TBody>
      </Table>
      <hr />
      <div className="pb-2">
        <b>Pesanan ini selambat-lambatnya telah sampai pada :</b>
      </div>
      <Row>
        <Col md>
          <InfoItemHorizontal
            label="Tanggal"
            width={125}
            text={
              data?.tgl_pengiriman
                ? DateConvert(new Date(data.tgl_pengiriman)).detail
                : "-"
            }
          />
          <InfoItemHorizontal
            label="Pembayaran"
            width={125}
            text={data?.pembayaran ?? "-"}
          />
          <InfoItemHorizontal
            label="Dikirim ke"
            width={125}
            text={data?.alamat_tujuan_pengiriman ?? "-"}
          />
        </Col>
        <Col md>
          <div className="d-flex justify-content-end">
            <table>
              <tbody>
                <InfoItems
                  title="Sub Total"
                  value={
                    RupiahConvert(parseFloat(total).toString()).getWithComa
                  }
                />
                <InfoItems
                  title={`Diskon ${
                    DecimalConvert(data.diskon ?? 0).getWithComa
                  } %`}
                  value={RupiahConvert(nilaiDiskon.toString()).getWithComa}
                />
                <InfoItems
                  title="Harga Setelah DIskon"
                  value={
                    RupiahConvert(parseFloat(setelahDiskon).toString())
                      .getWithComa
                  }
                />
                <InfoItems
                  title={`PPN ${DecimalConvert(data.ppn ?? 0).getWithComa} %`}
                  value={RupiahConvert(nilaiPPN.toString()).getWithComa}
                />
                <InfoItems
                  title="Harga Setelah Pajak"
                  value={
                    RupiahConvert(parseFloat(setelahPPN).toString()).getWithComa
                  }
                />
                <InfoItems
                  title="Biaya Angkut"
                  value={
                    RupiahConvert(parseFloat(totalBiayaAngkut).toString())
                      .getWithComa
                  }
                />
                <InfoItems
                  title="Grand Total"
                  value={
                    RupiahConvert(parseFloat(grandTotal).toString()).getWithComa
                  }
                />
                <InfoItems
                  title={`DP ${DecimalConvert(data.dp ?? 0).getWithComa} %`}
                  value={
                    RupiahConvert(parseFloat(nilaiDP).toString()).getWithComa
                  }
                />
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
      <hr />
      <InfoItemVertical label="Keterangan" text={data?.keterangan ?? "-"} />

      <TTDSection />
    </>
  );
};

export const ModalPurchaseOrder = ({ show, onHide, data }) => {
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: true,
    error: true,
  });
  const [dataDetail, setDataDetail] = useState({});

  const getInitialData = () => {
    setFetchingStatus({
      loading: true,
      error: true,
    });

    PenerimaanBarangApi.getSinglePO({
      id_purchase_order: data?.id_purchase_order,
    })
      .then((res) => {
        setDataDetail(res?.data?.data ?? {});
        setFetchingStatus({
          loading: false,
          error: false,
        });
      })
      .catch(() => {
        setFetchingStatus({
          loading: false,
          error: true,
        });
      });
  };

  useEffect(() => {
    getInitialData();
  }, [data?.id_purchase_order]);

  return (
    <Modal size="xl" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <b>Detail Purchase Order</b>
      </Modal.Header>
      <Modal.Body>
        {fetchingStatus.loading || fetchingStatus.error ? (
          <DataStatus
            loading={fetchingStatus.loading}
            text={
              fetchingStatus.loading ? "Memuat data . . ." : "Data gagal dimuat"
            }
          />
        ) : (
          <ModalBodyDetail data={dataDetail} />
        )}
      </Modal.Body>
    </Modal>
  );
};
