import React, { useState, useEffect } from "react";
import {
  Th,
  Td,
  Tr,
  DataStatus,
  TdFixed,
  InputQtyDecimal,
  InfoItemHorizontal,
  ThFixed,
  THead,
  TBody,
  SelectSearch,
  ActionButton,
} from "components";
import {
  ButtonCreate,
  ButtonDelete,
  ButtonUpdate,
} from "@bhawanadevteam/react-core";
import { Table, ButtonGroup } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { Formik, useFormikContext } from "formik";
import { DecimalConvert } from "utilities";
import { IoAddOutline, IoCheckmark, IoClose } from "react-icons/io5";
import { ConvertItepApi, StockFisikApi } from "api";
import * as Yup from "yup";

const TableSection = ({ dropdown, type }) => {
  const { values, setValues } = useFormikContext();
  const [update, setUpdate] = useState("");

  const TableForm = ({ edit, data }) => {
    const [dataKelompok, setDataKelompok] = useState([]);

    const [dataJenis, setDataJenis] = useState([]);
    const [dataItem, setDataItem] = useState([]);

    const [loading, setLoading] = useState({
      jenis: false,
      item: false,
    });

    const mappingDataKelompok = (data) =>
      data.map((val) => ({
        value: val?.id_kelompok,
        label: val?.nama_kelompok,
      }));

    const mappingDataJenis = (data) =>
      data.map((val) => ({
        value: val?.id_jenis,
        label: val?.nama_jenis,
      }));

    const mappingDataItem = (data) =>
      data.map((val) => ({
        ...val,
        value: val?.id_item_buaso,
        label: val?.nama_item,
        namaSatuan: val?.satuan_pakai,
        idItemBarang: val?.id_item_buaso,
      }));

    const getDataItemHandler = (id_jenis) => {
      setLoading((prev) => ({
        ...prev,
        item: true,
      }));

      StockFisikApi.dropdownItem({
        id_jenis,
      })
        .then((item) => {
          const mappingItem = mappingDataItem(item.data.data);

          setDataItem(mappingItem);
        })
        .catch(() => {
          window.alert("Data gagal dimuat");
        })
        .finally(() => {
          setLoading((prev) => ({
            ...prev,
            item: false,
          }));
        });
    };

    const getDataJenisHandler = (id_kelompok) => {
      setLoading((prev) => ({
        ...prev,
        jenis: true,
      }));

      StockFisikApi.dropdownJenis({
        id_kelompok,
      })
        .then((jenis) => {
          const mappingJenis = mappingDataJenis(jenis.data.data);

          setDataJenis(mappingJenis);
        })
        .catch(() => {
          window.alert("Data gagal dimuat");
        })
        .finally(() => {
          setLoading((prev) => ({
            ...prev,
            jenis: false,
          }));
        });
    };

    const getDataKelompokHandler = () => {
      StockFisikApi.dropdownKelompok({
        id_buaso: 1,
      })
        .then((kelompok) => {
          const mappingKelompok = mappingDataKelompok(kelompok.data.data);

          setDataKelompok(mappingKelompok);
        })
        .catch(() => {
          window.alert("Data gagal dimuat");
        });
    };

    const tableFormIntialValues = {
      idKelompok: data?.idKelompok ?? "",
      namaKelompok: data?.namaKelompok ?? "",
      idJenis: data?.idJenis ?? "",
      namaJenis: data?.namaJenis ?? "",
      idItem: data?.idItem ?? "",
      namaItem: data?.namaItem ?? "",
      qtyStock: data?.qtyStock ?? "",
      idSatuan: data?.idSatuan ?? "",
      namaSatuan: data?.namaSatuan ?? "",
    };
    const tableValidationSchema = Yup.object().shape({
      qtyStock: Yup.string().required("Masukkan qty"),
      idKelompok: Yup.string().required("pilih kelompok barang"),
      idJenis: Yup.string().required("pilih jenis barang"),
      idItem: Yup.string().required("pilih item barang"),
    });

    const tableSubmitHandler = (value) => {
      if (data) {
        setValues({
          ...values,
          detail: values.detail.map((val, index) =>
            update === index ? value : val
          ),
        });
        setUpdate(undefined);
      }
      if (!data) {
        setValues({ ...values, detail: [value, ...values.detail] });
      }
    };

    useEffect(() => {
      getDataKelompokHandler();
      return () => {};
    }, []);

    return (
      <Formik
        enableReinitialize
        initialValues={tableFormIntialValues}
        validationSchema={tableValidationSchema}
        onSubmit={tableSubmitHandler}
      >
        {({ values, setValues, errors, touched, handleSubmit }) => (
          <Tr>
            <Td colSpan={2}>
              <SelectSearch
                placeholder="Pilih salah satu"
                option={dataKelompok}
                defaultValue={{
                  value: values.idKelompok ?? "",
                  label: values.namaKelompok ?? "Pilih kelompok barang dahulu",
                }}
                onChange={(val) => {
                  setValues({
                    ...values,
                    idKelompok: val.value,
                    namaKelompok: val.label,
                  });
                  getDataJenisHandler(val.value);
                }}
                error={Boolean(errors.idKelompok) && touched.idKelompok}
                errorText={
                  Boolean(errors.idKelompok) &&
                  touched.idKelompok &&
                  errors.idKelompok
                }
              />
            </Td>
            <Td>
              <SelectSearch
                loading={loading.jenis}
                isDisabled={!values?.idKelompok}
                placeholder="Pilih salah satu"
                option={dataJenis}
                defaultValue={{
                  value: values.idJenis ?? "",
                  label: values.namaJenis ?? "Pilih jenis barang dahulu",
                }}
                onChange={(val) => {
                  setValues({
                    ...values,
                    idJenis: val.value,
                    namaJenis: val.label,
                  });
                  getDataItemHandler(val.value);
                }}
                error={Boolean(errors.idJenis) && touched.idJenis}
                errorText={
                  Boolean(errors.idJenis) && touched.idJenis && errors.idJenis
                }
              />
            </Td>
            <Td>
              <SelectSearch
                loading={loading.item}
                isDisabled={!values?.idJenis}
                placeholder="Pilih salah satu"
                option={dataItem}
                defaultValue={{
                  value: values.idItem ?? "",
                  label: values.namaItem ?? "Pilih jenis barang dahulu",
                }}
                onChange={(val) => {
                  setValues({
                    ...values,
                    idItem: val.value,
                    namaItem: val.label,
                    namaSatuan: val.namaSatuan,
                  });
                }}
                error={Boolean(errors.idItem) && touched.idItem}
                errorText={
                  Boolean(errors.idItem) && touched.idItem && errors.idItem
                }
              />
            </Td>
            <Td>{values?.namaSatuan ?? "-"}</Td>
            <Td>
              <InputQtyDecimal
                placeholder="0"
                value={values?.qtyStock ?? null}
                onChange={(e) => setValues({ ...values, qtyStock: e })}
                error={Boolean(errors.qtyStock) && touched.qtyStock}
                errorText={
                  Boolean(errors.qtyStock) &&
                  touched.qtyStock &&
                  errors.qtyStock
                }
              />
            </Td>
            <Td>
              {edit ? (
                <ButtonGroup>
                  <ActionButton
                    variant="success"
                    size="sm"
                    onClick={handleSubmit}
                  >
                    <IoCheckmark />
                  </ActionButton>
                  <ActionButton
                    variant="danger"
                    size="sm"
                    onClick={() => setUpdate(null)}
                  >
                    <IoClose />
                  </ActionButton>
                </ButtonGroup>
              ) : (
                <ActionButton size="sm" onClick={handleSubmit}>
                  <IoAddOutline />
                </ActionButton>
              )}
            </Td>
          </Tr>
        )}
      </Formik>
    );
  };

  const onQtyStockChange = (value, index) => {
    const newData = values?.detail?.map((val, i) => {
      return i === index
        ? {
            ...val,
            qtyStock: parseFloat(value) > 0 ? parseFloat(value) : 0,
          }
        : {
            ...val,
            qtyStock:
              parseFloat(values?.detail[i]?.qtyStock) > 0
                ? parseFloat(values?.detail[i]?.qtyStock)
                : 0,
          };
    });

    setValues({ ...values, detail: newData });
  };

  return (
    <>
      <div className="p-1">
        <b>List Item Stock Fisik</b>
      </div>

      <Table className="bg-white" bordered>
        <THead className="bg-light">
          <Th>No</Th>
          <Th>Kelompok Barang</Th>
          <Th>Jenis Barang</Th>
          <Th>Item Barang</Th>
          <Th>Satuan Pakai</Th>
          <Th>Qty</Th>
          {type !== "read" && <ThFixed>Aksi</ThFixed>}
        </THead>
        <TBody>
          {type !== "read" && <TableForm />}
          {values?.detail?.length > 0 ? (
            values?.detail?.map((val, index) =>
              update === index ? (
                <TableForm edit data={val} />
              ) : (
                <Tr key={index}>
                  <TdFixed>{index + 1}</TdFixed>
                  <Td>{val.namaKelompok ?? "-"}</Td>
                  <Td>{val.namaJenis ?? "-"}</Td>
                  <Td>{val.namaItem ?? "-"}</Td>
                  <Td className={"text-center"}>{val.namaSatuan ?? "-"}</Td>
                  <Td>
                    {DecimalConvert(parseFloat(val.qtyStock ?? 0)).getWithComa}
                  </Td>
                  {type !== "read" && (
                    <Td>
                      <ButtonGroup>
                        <ButtonUpdate
                          noText
                          icon
                          variant="success"
                          onClick={() => setUpdate(index)}
                        />
                        <ButtonDelete
                          noText
                          icon
                          onClick={() => {
                            const filterData = values.detail.filter(
                              (res, ind) => ind !== index
                            );
                            setValues({ ...values, detail: filterData });
                          }}
                        />
                      </ButtonGroup>
                    </Td>
                  )}
                </Tr>
              )
            )
          ) : (
            <tr>
              <td colSpan={10}>
                <DataStatus text="Tidak ada data" />
              </td>
            </tr>
          )}
        </TBody>
      </Table>
    </>
  );
};

export default TableSection;
