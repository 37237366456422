import { useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Formik } from "formik";
import Axios from "axios";
import { Alert, DataStatus, ActionButton } from "components";
import { StockFisikApi } from "api";
import { TableSection, FormSection } from "../Section";
import {
  formInitialValues,
  formSubmitMapper,
  formValidationSchema,
} from "../Utils";

const UbahStockFisik = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  const [dataUpdate, setDataUpdate] = useState({});
  const [dataBahan, setDataBahan] = useState([]);
  const [dataUnitProduksi, setDataUnitProduksi] = useState([]);
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: false,
    success: false,
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const mappingDataUnitProduksi = (data) =>
    data.map((val) => ({
      value: val?.id_unit_produksi,
      label: val.nama_unit_produksi,
    }));

  const getInitialValues = () => {
    setFetchingStatus({ loading: true, success: false });

    Axios.all([
      StockFisikApi.getSingle({ id_stock_fisik_master: id }),
      StockFisikApi.getBahan(),
      StockFisikApi.dropdownUnitProduksi(),
    ])
      .then(
        Axios.spread((single, bahan, unit_produksi) => {
          const mapDataUnitProduksi = mappingDataUnitProduksi(
            unit_produksi.data.data
          );
          const idItemBarang = new Set(
            single?.data?.data?.detail.map((val) => val.id_item_barang)
          );

          // const mergeData = {
          //   ...single?.data?.data,
          //   detail: [
          //     ...single?.data?.data?.detail,
          //     ...bahan?.data?.data.filter(
          //       (val) => !idItemBarang.has(val.idItemBarang)
          //     ),
          //   ],
          // };

          const mergeData = {
            ...single?.data?.data,
            detail: [...single?.data?.data?.detail],
          };

          setDataUpdate(mergeData);
          setDataBahan(bahan.data.data);
          setDataUnitProduksi(mapDataUnitProduksi);

          setFetchingStatus({
            loading: false,
            success: true,
          });
        })
      )
      .catch(() => {
        setAlertConfig({
          show: false,
          variant: "primary",
          text: "Data gagal dimuat!",
        });
        setFetchingStatus({
          loading: false,
          success: false,
        });
      });
  };

  const formSubmitHandler = (values) => {
    StockFisikApi.update(formSubmitMapper(values))
      .then(() => {
        history.push("/inventory/transaksi/stock-fisik", {
          alert: {
            show: true,
            variant: "primary",
            text: "Data berhasil disimpan!",
          },
          do: location?.state?.do,
        });
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal disimpan!",
        });
      })
      .finally(() => {});
  };

  useEffect(() => {
    setNavbarTitle("Stock Fisik");
    getInitialValues();
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues(dataUpdate)}
      validationSchema={formValidationSchema}
      onSubmit={formSubmitHandler}
    >
      {(formik) => (
        <>
          <Alert
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            showCloseButton={true}
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
          />
          {fetchingStatus.loading ? (
            <DataStatus loading text="Memuat . . ." />
          ) : fetchingStatus.success ? (
            <form onSubmit={formik.handleSubmit}>
              <FormSection
                dataUnitProduksi={dataUnitProduksi}
                fetchingStatus={fetchingStatus}
              />
              <TableSection />
              <div className="d-flex justify-content-end">
                <ActionButton
                  type="submit"
                  text="Ubah"
                  variant="success"
                  loading={formik.isSubmitting}
                />
              </div>
            </form>
          ) : (
            <DataStatus text="Data gagal dimuat" />
          )}
        </>
      )}
    </Formik>
  );
};

export default UbahStockFisik;
