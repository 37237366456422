import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Formik } from "formik";
import Axios from "axios";
import { Alert, DataStatus } from "components";
import { ConvertItepApi } from "api";
import { TableSection, FormSection } from "../Section";
import {
  formInitialValues,
  formSubmitMapper,
  formValidationSchema,
} from "../Utils";

const DetailConvertItem = ({ setNavbarTitle }) => {
  const { id } = useParams();
  const [detailData, setDetailData] = useState([]);
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: false,
    success: true,
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const getInitialValues = () => {
    setFetchingStatus({ loading: true, success: false });

    Axios.all([ConvertItepApi.getSingle({ id_convert_item_master: id })])
      .then(
        Axios.spread((single) => {
          setDetailData(single.data.data);
          setFetchingStatus({
            loading: false,
            success: true,
          });
        })
      )
      .catch(() => {
        setAlertConfig({
          show: false,
          variant: "primary",
          text: "Data gagal dimuat!",
        });
        setFetchingStatus({
          loading: false,
          success: false,
        });
      });
  };

  useEffect(() => {
    setNavbarTitle("Convert Item");
    getInitialValues();
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues(detailData)}
      validationSchema={formValidationSchema}
    >
      {(formik) => (
        <>
          <Alert
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            showCloseButton={true}
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
          />
          {fetchingStatus.loading ? (
            <DataStatus loading text="Memuat . . ." />
          ) : fetchingStatus.success ? (
            <form onSubmit={formik.handleSubmit}>
              <FormSection />
              <TableSection type="read" />
            </form>
          ) : (
            <DataStatus text="Data gagal dimuat" />
          )}
        </>
      )}
    </Formik>
  );
};

export default DetailConvertItem;
